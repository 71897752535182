<template>
	<div class="border border-default-200 rounded-lg">
		<div
			class="px-5 py-3 text-center font-medium text-default-500"
			v-if="itemsCount == 0"
		>
			<div class="my-32">O carrinho está vazio!</div>
		</div>

		<div
			class="max-w-screen-lg rounded-md text-gray-600 p-2"
			v-for="product in products"
			:key="product.id"
		>
			<div
				class="grid grid-cols-12 gap-0 w-full text-gray-600 shadow-xs"
				v-if="product.qty > 0"
			>
				<ImageComponent
					:name="product.id"
					:image_url="product.options.image_url"
					class="w-20 h-20 m-auto md:col-span-2 col-span-4"
				/>

				<div
					class="md:col-span-4 col-span-8 my-auto line-clamp-2 flex flex-col"
				>
					<div>
						{{ product.name }}
						{{
							product.options?.group_id != null
								? "(" + product.options?.group_name + ")"
								: ""
						}}
					</div>

					<div class="text-xs" v-if="product.options?.obs != ''">
						<b class="ml-2">Observação:</b> {{ product.options?.obs }}
					</div>
				</div>
				<div class="md:col-span-2 col-span-4 font-black m-auto">
					{{ toCurrency(product.price) }}
				</div>

				<div
					class="m-auto flex items-center p-2 md:col-span-4 col-span-8 text-lg"
				>
					<div
						v-if="product.qty > 1 && product"
						class="select-none right-2 bottom-2 inline-flex items-center justify-center rounded-full bg-white p-2 text-center font-medium shadow transition-all duration-500"
					>
						<a
							v-on:click="decreaseProduct(product)"
							class="px-3 cursor-pointer"
						>
							<Icon.Minus :size="18" />
						</a>
						<span>{{ product.qty }}</span>
						<a
							v-on:click="increaseProduct(product)"
							class="px-3 cursor-pointer"
						>
							<Icon.Plus :size="18" />
						</a>
					</div>

					<div
						v-if="product.qty == 1"
						class="select-none right-2 bottom-2 inline-flex items-center justify-center rounded-full bg-white p-2 text-center font-medium shadow transition-all duration-500"
					>
						<a @click="removeProduct(product)" class="px-3 cursor-pointer">
							<Icon.Trash class="w-4 h-4 cursor-pointer" />
						</a>
						<span>{{ product.qty }}</span>
						<a
							v-on:click="increaseProduct(product)"
							class="px-3 cursor-pointer"
						>
							<Icon.Plus :size="18" />
						</a>
					</div>

					<div
						v-if="product.qty == 0"
						class="select-none right-2 bottom-2 inline-flex items-center justify-center rounded-full bg-white p-2 text-center font-medium shadow transition-all duration-500"
					>
						<a
							v-on:click="increaseProduct(product)"
							class="px-3 cursor-pointer"
						>
							<Icon.Plus :size="18" />
						</a>
					</div>
				</div>
			</div>
			<div v-if="product.options?.addons?.length > 0" class="text-xs">
				<div
					class="ml-10"
					v-for="addons in product.options?.addons"
					:key="product.rowId + addons.id"
				>
					<div class="ml-2">
						<b>{{ addons.name }}</b>
					</div>
					<div
						class="ml-4"
						v-for="item in addons.items"
						:key="product.rowId + addons.id + item.id"
					>
						{{ item.qty }} x {{ item.name }} =
						{{ toCurrency(item.qty * item.price) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
</script>

<script>
export default {
	props: {
		store_id: { type: String },
		currentCart: { type: Object },
		currentAddress: { type: Object },
	},
	data() {
		return {
			products: [],
			orderValues: {},
			itemsCount: 0,
			deliveryMode: "delivery",
		};
	},
	mounted() {
		this.productsOnCart = [];
		for (const [key, value] of Object.entries(
			this.currentCart.products ?? {}
		)) {
			this.productsOnCart.push(value);
		}
		this.itemsCount = this.currentCart.productsQtd;
		this.total = this.currentCart.total ?? 0;
		this.country = this.currentCart.country;
		this.deliveryMode = this.currentCart.deliveryMode ?? "delivery";

		this.onCartUpdated({
			products: this.productsOnCart,
			itemsCount: this.currentCart.productsQtd,
			deliveryMode: this.deliveryMode,
			country: this.country,
		});
		this.emitter.on("cart.updated", this.onCartUpdated);
	},
	created() {},
	methods: {
		onCartUpdated(data) {
			this.products = data.products;
			this.itemsCount = data.itemsCount;
			this.orderValues = data.orderValues;
		},
		increaseProduct(product) {
			document.Cart.increase(product.rowId, 1);
		},
		decreaseProduct(product) {
			document.Cart.decrease(product.rowId, 1);
		},
		add(product, qty = 1) {
			if (this.deliveryMode != "delivery" && this.currentAddress.name == "")
				this.emitter.emit("address.select");
			else {
				document.Cart.add(product.id, qty);
			}
		},
		removeProduct(product) {
			document.Cart.remove(product.rowId);
		},
		toggle(id) {
			document.getElementById(id).classList.toggle("hidden");
		},
		reset() {
			this.historyMode = true;
		},

		getProductPhoto(id) {
			return 1; //this.store.get
		},

		toCurrency(value) {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}
			var formatter = new Intl.NumberFormat("pt-PT", {
				style: "currency",
				currency: "EUR",
			});

			return formatter.format(value);
		},
	},
};
</script>
