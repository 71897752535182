<template>
	<div
		:class="
			'shadow rounded-2xl bg-clip-border break-words grid grid-cols-1 w-full font-[HelveticaNeue] relative ' +
			bgColor
		"
	>
		<div
			v-if="data.type == 'takeaway'"
			class="font-sans leading-normal text-center text-[12px] py-1 bg-[#25253D] rounded-t-2xl text-white uppercase"
		>
			{{ data.type }}
		</div>
		<div
			v-else
			class="font-sans leading-normal text-center text-[12px] py-1 bg-[#1654f4] rounded-t-2xl text-white uppercase"
		>
			{{ data.type }}
		</div>

		<integrator-icon
			v-if="user_type != 'merchant' && data.driver_id"
			:integrator="data.delivery_platform"
			class="w-5 absolute z-10 top-1 right-1"
		/>

		<div :class="'py-2 px-4 flex flex-row ' + textColor">
			<div class="w-8/12">
				<div
					v-if="user_type != 'merchant'"
					class="font-sans leading-normal line-clamp-1 font-semibold"
				>
					{{ data.store.name }}
				</div>
				<div class="font-sans leading-normal line-clamp-1">
					<span class="font-semibold text-lg">#{{ data.code }}</span>
					{{ data.eater_name }}
				</div>
				<div class="font-sm">
					<div class="" v-if="data.type == 'takeaway'">
						{{ moment(data.created_at).format("HH:mm")
						}}<span v-if="data.estimated_ready_for_pickup_at">
							<Icon.ArrowRight :size="18" class="inline" />
							{{
								moment(data.estimated_ready_for_pickup_at).format("HH:mm")
							}}</span
						>
					</div>
					<div class="" v-else>
						{{ moment(data.created_at).format("HH:mm")
						}}<span v-if="data.estimated_delivery_at">
							<Icon.ArrowRight :size="18" class="inline" />
							{{ moment(data.estimated_delivery_at).format("HH:mm") }}</span
						>
					</div>
				</div>
			</div>

			<div v-if="data.price_with_discount > 0" class="w-4/12 flex flex-col">
				<div class="text-right my-auto text-2xl">
					{{ toCurrency(data.price_with_discount, "") }}
					<span class="">€</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue";
import * as Icon from "lucide-vue-next";
import moment from "moment";
</script>

<script>
export default {
	components: {},
	props: {
		data: { type: Object },
		user_type: { type: String, default: "admin" },
		status: { type: String, default: "NEW" },
		color: { type: String, default: "white" },
		textcolor: { type: String, default: "black" },
		selected: { type: Boolean, default: false },
	},
	data() {
		return {
			bgColor: computed(() => {
				if (this.status == "NEW")
					return !this.selected ? "bg-[#1654f4E6]" : "bg-[#1654f4B3]";
				if (this.status == "READY")
					return !this.selected ? "bg-[#44A046E6]" : "bg-[#44A046B3]";
				if (this.status == "ON_DELIVERY")
					return !this.selected ? "bg-[#E29E17E6]" : "bg-[#E29E17B3]";
				if (this.status == "DELIVERED")
					return !this.selected ? "bg-[#FFFFFF]" : "bg-[#EDEDED]";
			}),
			textColor: computed(() => {
				return this.status == "DELIVERED" ? "text-[#333333]" : "text-white";
			}),
			IconComponent: computed(() => Icon[this.icon]),
		};
	},
	mounted() {},
	methods: {
		toCurrency(value, currency = "EUR") {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}

			var formatter;

			if (currency == "") {
				formatter = new Intl.NumberFormat("pt-PT", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			} else {
				formatter = new Intl.NumberFormat("pt-PT", {
					style: "currency",
					currency: currency,
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			}

			return formatter.format(value);
		},
	},
};
</script>
