 <template>
	<div class="flex items-center justify-between w-full mb-6">
		<h1 class="text-xl font-bold">Definições - Gerais</h1>
	</div>
	<div class="grid grid-col-1 gap-4">
		<Loading :enabled="loading" />

		<div
			v-if="!loading"
			class="flex-row gap-3 grow min-h-full p-2"
			style="min-height: calc(100vh - 170px)"
		>
			<form
				@submit.prevent="settingsTabSave"
				@keydown.enter="$event.preventDefault()"
			>
				<div class="grid xl:grid-cols-3 xs:grid-cols-1 gap-x-10 gap-y-5">
					<div class="">
						<label class="block text-sm font-medium text-default-900 mb-2"
							>Logo</label
						>
						<image-upload
							class="h-20"
							name="logo"
							:store_id="store.id"
							:maxSize="5"
							accept="png,jpg,jpeg"
							@file-uploaded="uploadLogo"
						/>
					</div>
					<div class="">
						<label class="block text-sm font-medium text-default-900 mb-2"
							>Home Background</label
						>
						<image-upload
							class="h-20"
							:name="'bg-home'"
							:store_id="store.id"
							:maxSize="5"
							accept="png,jpg,jpeg"
							@file-uploaded="uploadHomeBackground"
						/>
					</div>
					<div class="">
						<label class="block text-sm font-medium text-default-900 mb-2"
							>Empresa</label
						>
						<select
							name="company_id"
							id="company"
							v-model="store.company_id"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						>
							<option
								v-for="company in companies"
								:key="company.id"
								:value="company.id"
							>
								{{ company.name }}
							</option>
						</select>

						<label class="block text-sm font-medium text-default-900 mb-2 mt-2"
							>Pausado para novos pedidos?</label
						>
						<input
							type="checkbox"
							id="pause_new_orders"
							v-model="store.pause_new_orders"
							true-value="true"
							false-value="false"
							class="relative w-[3.25rem] h-7 bg-default-200 focus:ring-0 checked:bg-none checked:!bg-primary border-2 border-transparent rounded-3xl cursor-pointer transition-colors ease-in-out duration-200 appearance-none focus:ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-3xl before:transform before:transition before:ease-in-out before:duration-200"
						/>
					</div>

					<div class="xl:col-span-3">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="home_header"
							>Header HTML</label
						>
						<textarea
							id="home_header"
							name="home_header"
							rows="6"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.home_header"
						/>
					</div>

					<div>
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="name"
							>Nome</label
						>
						<input
							id="name"
							name="name"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.name"
						/>
					</div>

					<div>
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="email"
							>E-mail</label
						>
						<input
							id="email"
							name="email"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.email"
						/>
					</div>

					<div>
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="phone"
							>Telefone</label
						>
						<input
							id="phone"
							name="phone"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.phone"
						/>
					</div>

					<div>
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="url"
							>URL</label
						>
						<input
							id="url"
							name="url"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.url"
						/>
					</div>

					<div>
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="hostnames"
							>Hostnames</label
						>
						<input
							id="hostnames"
							name="hostnames"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.hostnames"
						/>
					</div>

					<div class="xl:col-span-2">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="address"
							>Endereço</label
						>
						<input
							id="address"
							name="address"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.address"
						/>
					</div>

					<div>
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="address_number"
							>Número</label
						>
						<input
							id="address_number"
							name="address_number"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.address_number"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="appartment_number"
							>Apartamento Número</label
						>
						<input
							id="appartment_number"
							name="appartment_number"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.appartment_number"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="zipcode"
							>Código Postal</label
						>
						<input
							id="zipcode"
							name="zipcode"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.zipcode"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="city"
							>Cidade</label
						>
						<input
							id="city"
							name="city"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.city"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="state"
							>Estado</label
						>
						<input
							id="state"
							name="state"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.state"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="country"
							>País</label
						>
						<input
							id="country"
							name="country"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.country"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="lat"
							>Latitude</label
						>
						<input
							id="lat"
							name="lat"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.lat"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="lng"
							>Longitude</label
						>
						<input
							id="lng"
							name="lng"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.lng"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="service_fee"
							>Taxa de serviço %</label
						>
						<input
							id="service_fee"
							name="service_fee"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.service_fee"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="delivery_km"
							>Taxa entrega por KM</label
						>
						<input
							id="delivery_km"
							name="delivery_km"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.delivery_km"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="delivery_base"
							>Taxa de Entrega base</label
						>
						<input
							id="delivery_base"
							name="delivery_base"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.delivery_base"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="minutes_to_prepare"
							>Minutos de preparo</label
						>
						<input
							id="minutes_to_prepare"
							name="minutes_to_prepare"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.minutes_to_prepare"
						/>
					</div>

					<div class="">
						<label
							class="block text-sm font-medium text-default-900 mb-2"
							for="max_distance_to_delivery"
							>Raio de entrega (em KM)</label
						>
						<input
							id="max_distance_to_delivery"
							name="max_distance_to_delivery"
							class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							type="text"
							:value="store.max_distance_to_delivery"
						/>
					</div>
				</div>

				<div class="flex justify-end">
					<button
						type="submit"
						class="px-6 py-3 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
					>
						Gravar
					</button>
				</div>
			</form>
		</div>
	</div>

	<div
		v-if="menuPopupOpened"
		class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
		id="modal-product"
	>
		<div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
		<div
			class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
		>
			<!--content-->
			<div>
				<Icon.X
					@click="menuClosePopup"
					class="cursor-pointer absolute top-1 right-1"
				/>
				<!--body-->
				<div class="text-center gap-4 p-5 flex-auto justify-center">
					<div class="mb-4" v-if="currentProductData.id == ''">
						Novo produto
					</div>
					<div class="mb-4" v-else>Editar produto</div>
					<input
						id="name"
						name="name"
						class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						type="text"
						placeholder="Nome"
						v-model="currentProductData.name"
					/>
					<div class="text-center grid grid-cols-2 gap-4 p-5 justify-center">
						<div>
							<label class="">Foto</label>
							<image-upload
								v-if="currentProductData.id != ''"
								class="h-24"
								:name="currentProductData.id"
								:product="currentProductData"
								:store_id="store_id"
								:maxSize="5"
								accept="png,jpg,jpeg"
								@file-uploaded="uploadImage"
							/>
						</div>
						<div class="mb-4">
							<label for="description" class="">Descrição</label>
							<textarea
								id="description"
								name="description"
								rows="5"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								placeholder="Descrição"
								v-model="currentProductData.description"
							/>
						</div>

						<div class="mb-4">
							<label for="price" class="">Preço</label>
							<input
								id="price"
								name="price"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								placeholder="Preço"
								v-model="currentProductData.price"
							/>
						</div>

						<div class="mb-4">
							<label for="integration_id" class="">Integration Id</label>

							<input
								type="text"
								id="integration_id"
								v-model="currentProductData.integration_id"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							/>
						</div>

						<div class="mb-4">
							<label for="availible" class="">Em estoque?</label>

							<input
								type="checkbox"
								id="availible"
								v-model="currentProductData.available"
								true-value="1"
								false-value="0"
								class="relative w-[3.25rem] h-7 bg-default-200 focus:ring-0 checked:bg-none checked:!bg-primary border-2 border-transparent rounded-3xl cursor-pointer transition-colors ease-in-out duration-200 appearance-none focus:ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-3xl before:transform before:transition before:ease-in-out before:duration-200"
							/>
						</div>
					</div>
					<div class="flex justify-end">
						<button
							@click="menuProductSave"
							class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Gravar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		v-if="menuCategoryPopupOpened"
		class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
		id="modal-category"
	>
		<div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
		<div
			class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
		>
			<!--content-->
			<div>
				<Icon.X
					@click="menuCategoryClosePopup"
					class="cursor-pointer absolute top-1 right-1"
				/>
				<!--body-->
				<div class="text-center gap-4 p-5 flex-auto justify-center">
					<div class="mb-4" v-if="currentCategoryData.id == ''">
						Nova Categoria
					</div>
					<div class="mb-4" v-else>Editar categoria</div>
					<input
						id="name"
						name="name"
						class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						type="text"
						v-model="currentCategoryData.name"
					/>
					<div class="flex justify-end">
						<button
							@click="menuCategorySave"
							class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Gravar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import moment from "moment";
import draggable from "vuedraggable";
import Loading from "../Loading.vue";
import FormData from "form-data";
</script>

<script>
export default {
	components: {},
	props: {
		store: { type: Object },
		companies: { type: Array },
		ubereats_client_id: { type: String, default: "" },
	},
	data() {
		return {
			loading: true,

			menuItems: [],
			menuItemsDrag: false,
			menuPopupOpened: false,
			menuCategoryPopupOpened: false,
			currentProduct: {},
			currentProductData: {},
			currentCategory: {},
			currentCategoryData: {},

			orders: [],
			orders_new: [],
			orders_ready: [],
			orders_accepted: [],
			orders_delivering: [],
			orders_done: [],
			order: {},
			quoteId: "",
			quotePrice: 0,
			quoteDistance: 0,
			quoteExpiration: null,
			address_zipcode: "",
			address_lat: "",
			address_lng: "",
			address_area_level_1: "",
			address_area_level_2: "",
			address_area_level_3: "",
			address_country: "",
			preparation_time: 20,
			newOrder: {},
			newOrderTemp: {},
			sugestionsList: [],
			confirmMode: false,
			finalConfirm: false,
			confirmPlace: null,
			confirmPlace2: null,
			confirmPlaceId: 0,
			confirmObs: "",
			confirmObsDefault: "",
			eater_name: "",
			eater_phone: "",
			eater_nif: "",
			address_number: "",
			appartment_number: "",
			address_number_error: "",
			newOrderModalOpened: false,
			printOrder: {
				id: "receipt_print",
				popTitle: "Pedido #",
				beforeOpenCallback(vue) {
					//vue.printLoading = true
					//console.log('打开之前')
				},
				openCallback(vue) {
					//vue.printLoading = false
					//console.log('执行了打印')
				},
				closeCallback(vue) {
					//console.log('关闭了打印工具')
				},
			},
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			const self = this;
			this.loading = true;
			self.menuItems = [];

			axios
				.get("/admin/store/" + this.store_id + "/menu")
				.then((response) => {
					self.menuItems = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		uploadLogo(file) {
			let data = new FormData();
			data.append("name", "logo");
			data.append("image", file);

			axios
				.post("/admin/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				});
		},
		uploadHomeBackground(file) {
			let data = new FormData();
			data.append("name", "bg-home");
			data.append("image", file);

			axios
				.post("/admin/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				});
		},
		uploadImage(file, name, product_id, dom) {
			let data = new FormData();
			data.append("name", name);
			data.append("product_id", product_id);
			data.append("image", file);

			axios
				.post("/admin/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				})
				.finally((error) => {
					if (dom) {
						var dt = new Date();
						if (dom.src.length >= 0) {
							dom.src = dom.src + "?" + dt.getTime();
						}
					}
				});
		},
		settingsTabSave(submitEvent) {
			axios
				.post("/admin/store/" + this.store.id + "/settings/update", {
					company_id: submitEvent.target.elements.company_id.value,
					name: submitEvent.target.elements.name.value,
					email: submitEvent.target.elements.email.value,
					phone: submitEvent.target.elements.phone.value,
					url: submitEvent.target.elements.url.value,
					hostnames: submitEvent.target.elements.hostnames.value,
					home_header: submitEvent.target.elements.home_header.value,
					pause_new_orders:
						submitEvent.target.elements.pause_new_orders.checked,
					address: submitEvent.target.elements.address.value,
					address_number: submitEvent.target.elements.address_number.value,
					appartment_number:
						submitEvent.target.elements.appartment_number.value,
					zipcode: submitEvent.target.elements.zipcode.value,
					city: submitEvent.target.elements.city.value,
					state: submitEvent.target.elements.state.value,
					country: submitEvent.target.elements.country.value,
					lat: submitEvent.target.elements.lat.value,
					lng: submitEvent.target.elements.lng.value,
					service_fee: submitEvent.target.elements.service_fee.value,
					delivery_km: submitEvent.target.elements.delivery_km.value,
					delivery_base: submitEvent.target.elements.delivery_base.value,
					minutes_to_prepare:
						submitEvent.target.elements.minutes_to_prepare.value,
					max_distance_to_delivery:
						submitEvent.target.elements.max_distance_to_delivery.value,
				})
				.then(() => {
					alert("updated!");
				})
				.catch((error) => {
					console.log(error);
				});
		},

		orderStatus(value) {
			switch (value) {
				case "NEW":
					return "Novo";
				case "ACCEPTED":
					return "Aceito";
				case "READY":
					return "Pronto";
				case "ON_DELIVERY":
					return "Em Entrega";
				case "FINISHED":
				case "DELIVERED":
					return "Finalizado";
				case "CANCELED":
					return "Cancelado";
				case "REJECTED":
					return "Rejeitado";
				case "BACK_TO_NEW":
					return "Devolvido";
				case "BACK_TO_ACCEPT":
					return "Devolvido";
				default:
					return value;
			}
		},

		toCurrency(value, currency = "EUR") {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}

			var formatter;

			if (currency == "") {
				formatter = new Intl.NumberFormat("pt-PT", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			} else {
				formatter = new Intl.NumberFormat("pt-PT", {
					style: "currency",
					currency: currency,
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			}

			return formatter.format(value);
		},
	},
};
</script>
