<template>
	<div>
		<div class="flex items-center justify-between w-full">
			<h4 class="text-xl font-bold">Dashboard</h4>
		</div>

		<div class="border-b border-gray-200 dark:border-gray-700">
			<ul
				class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
			>
				<li class="me-2">
					<a
						href="#"
						data-tab-target="#tabConfig"
						class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
					>
						<Icon.Settings :size="15" />
						<span class="hidden md:block">Configurações</span>
					</a>
				</li>
				<li class="me-2">
					<a
						href="#"
						data-tab-target="#tabHours"
						class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
					>
						<Icon.Clock :size="15" />
						<span class="hidden md:block">Horário de Funcionamento</span>
					</a>
				</li>
				<li class="me-2">
					<a
						href="#"
						data-tab-target="#tabOrders"
						class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
						aria-current="page"
					>
						<Icon.ReceiptText :size="15" />
						<span class="hidden md:block">Pedidos</span>
					</a>
				</li>
				<li class="me-2">
					<a
						href="#"
						data-tab-target="#tabMenu"
						class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
					>
						<Icon.BookOpen :size="15" />
						<span class="hidden md:block">Menu</span>
					</a>
				</li>
				<li class="me-2">
					<a
						href="#"
						data-tab-target="#tabImages"
						class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
					>
						<Icon.Images :size="15" />
						<span class="hidden md:block">Imagens</span>
					</a>
				</li>
				<li class="me-2">
					<a
						href="#"
						data-tab-target="#tabDelivery"
						class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group gap-2"
					>
						<Icon.Bike :size="15" />
						<span class="hidden md:block">Delivery</span>
					</a>
				</li>
			</ul>
		</div>

		<div class="mt-4">
			<div id="tabConfig" class="tab-content grid gap-5 text-gray-700 min-h-60">
				<h2 class="font-bold my-4">Configurações</h2>

				<form
					@submit.prevent="settingsTabSave"
					@keydown.enter="$event.preventDefault()"
				>
					<div class="grid xl:grid-cols-3 xs:grid-cols-1 gap-x-10 gap-y-5">
						<div class="">
							<label class="block text-sm font-medium text-default-900 mb-2"
								>Logo</label
							>
							<image-upload
								ref="logoUpload"
								class="h-20"
								name="logo"
								prefix="merchant"
								:store_id="store.id"
								:maxSize="5"
								accept="png,jpg,jpeg"
								@file-uploaded="uploadLogo"
							/>
						</div>
						<div class="">
							<label class="block text-sm font-medium text-default-900 mb-2"
								>Home Background ssss</label
							>
							<image-upload
								ref="bgUpload"
								class="h-20"
								prefix="merchant"
								:name="'bg-home'"
								:store_id="store.id"
								:maxSize="5"
								accept="png,jpg,jpeg"
								@file-uploaded="uploadHomeBackground"
							/>
						</div>
						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2 mt-2"
								>Pausado para novos pedidos?</label
							>
							<input
								type="checkbox"
								id="pause_new_orders"
								v-model="store.pause_new_orders"
								true-value="true"
								false-value="false"
								class="relative w-[3.25rem] h-7 bg-default-200 focus:ring-0 checked:bg-none checked:!bg-primary border-2 border-transparent rounded-3xl cursor-pointer transition-colors ease-in-out duration-200 appearance-none focus:ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-3xl before:transform before:transition before:ease-in-out before:duration-200"
							/>
						</div>

						<div class="xl:col-span-3">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="home_header"
								>Header HTML</label
							>
							<textarea
								id="home_header"
								name="home_header"
								rows="6"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.home_header"
							/>
						</div>

						<div>
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="name"
								>Nome</label
							>
							<input
								id="name"
								name="name"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.name"
							/>
						</div>

						<div>
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="email"
								>E-mail</label
							>
							<input
								id="email"
								name="email"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.email"
							/>
						</div>

						<div>
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="phone"
								>Telefone</label
							>
							<input
								id="phone"
								name="phone"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.phone"
							/>
						</div>

						<div>
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="url"
								>URL</label
							>
							<input
								id="url"
								name="url"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.url"
							/>
						</div>

						<div class="xl:col-span-2">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="address"
								>Endereço</label
							>
							<input
								id="address"
								name="address"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.address"
							/>
						</div>

						<div>
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="address_number"
								>Número</label
							>
							<input
								id="address_number"
								name="address_number"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.address_number"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="appartment_number"
								>Apartamento Número</label
							>
							<input
								id="appartment_number"
								name="appartment_number"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.appartment_number"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="zipcode"
								>Código Postal</label
							>
							<input
								id="zipcode"
								name="zipcode"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.zipcode"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="city"
								>Cidade</label
							>
							<input
								id="city"
								name="city"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.city"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="state"
								>Estado</label
							>
							<input
								id="state"
								name="state"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.state"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="country"
								>País</label
							>
							<input
								id="country"
								name="country"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.country"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="lat"
								>Latitude</label
							>
							<input
								id="lat"
								name="lat"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.lat"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="lng"
								>Longitude</label
							>
							<input
								id="lng"
								name="lng"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.lng"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="minutes_to_prepare"
								>Minutos de preparo</label
							>
							<input
								id="minutes_to_prepare"
								name="minutes_to_prepare"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.minutes_to_prepare"
							/>
						</div>

						<div class="">
							<label
								class="block text-sm font-medium text-default-900 mb-2"
								for="max_distance_to_delivery"
								>Raio de entrega (em KM)</label
							>
							<input
								id="max_distance_to_delivery"
								name="max_distance_to_delivery"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								:value="store.max_distance_to_delivery"
							/>
						</div>
					</div>

					<div class="flex justify-end">
						<button
							type="submit"
							class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Gravar
						</button>
					</div>
				</form>
			</div>

			<div id="tabHours" class="hidden tab-content text-gray-700 min-h-60">
				<h2 class="font-bold my-4">Horário de Funcionamento</h2>
				<div
					v-if="hoursLoading"
					class="w-full flex items-center justify-center p-40"
				>
					<div
						class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
						role="status"
					>
						<span
							class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
							>Loading...</span
						>
					</div>
				</div>

				<div v-if="!hoursLoading" class="grid grid-cols-1 gap-10">
					<div
						class="max-w-screen-lg rounded-md border border-gray-100 text-gray-600 shadow-md grid grid-cols-1 gap-2 p-4"
						v-for="hour in hoursItems"
						:key="hour.id"
					>
						<div class="grid grid-cols-5 gap-4">
							<Icon.Trash class="m-auto" @click="removeHour(hour)" />
							<label class="block text-sm font-medium text-default-900 mb-2"
								>Horário inicial</label
							>
							<input
								type="text"
								@input="updateStartInternalValue(hour, $event)"
								:value="hour.hour_start"
								placeholder="Horário inicial"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							/>

							<label class="block text-sm font-medium text-default-900 mb-2"
								>Horário final</label
							>
							<input
								type="text"
								@input="updateEndInternalValue(hour, $event)"
								:value="hour.hour_end"
								placeholder="Horário final"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							/>
						</div>
						<div class="grid md:grid-cols-7 grid-cols-3 gap-2">
							<button
								v-if="hour.monday == false"
								@click="hourWeek(hour, 'monday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Segunda
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'monday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Segunda
							</button>

							<button
								v-if="hour.tuesday == false"
								@click="hourWeek(hour, 'tuesday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Terça
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'tuesday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Terça
							</button>

							<button
								v-if="hour.wednesday == false"
								@click="hourWeek(hour, 'wednesday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Quarta
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'wednesday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Quarta
							</button>

							<button
								v-if="hour.thursday == false"
								@click="hourWeek(hour, 'thursday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Quinta
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'thursday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Quinta
							</button>

							<button
								v-if="hour.friday == false"
								@click="hourWeek(hour, 'friday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Sexta
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'friday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Sexta
							</button>

							<button
								v-if="hour.saturday == false"
								@click="hourWeek(hour, 'saturday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Sábado
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'saturday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Sábado
							</button>

							<button
								v-if="hour.sunday == false"
								@click="hourWeek(hour, 'sunday', true)"
								class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Domingo
							</button>
							<button
								v-else
								@click="hourWeek(hour, 'sunday', false)"
								class="block w-full rounded-lg py-2.5 px-4 bg-primary border border-default-200 focus:ring-transparent focus:border-default-200"
							>
								Domingo
							</button>
						</div>
					</div>

					<Icon.Plus @click="hoursAdd()" />
				</div>
			</div>

			<div id="tabOrders" class="hidden tab-content text-gray-700 min-h-60">
				<h2 class="font-bold my-4">Pedidos</h2>
				<EasyDataTable
					:headers="headers"
					:items="items"
					v-model:server-options="serverOptions"
					:server-items-length="serverItemsLength"
					:loading="ordersLoading"
				/>
			</div>

			<div id="tabMenu" class="hidden tab-content text-gray-700 min-h-60">
				<h2 class="font-bold my-4">Menu</h2>

				<div
					v-if="menuLoading"
					class="w-full flex items-center justify-center p-40"
				>
					<div
						class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
						role="status"
					>
						<span
							class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
							>Loading...</span
						>
					</div>
				</div>

				<div v-if="!menuLoading">
					<Icon.Plus @click="menuAddCategory(element)" />
					<draggable
						v-model="menuItems"
						group="category"
						@start="menuItemsDrag = true"
						@end="menuProductSavePositions()"
						item-key="id"
						handle=".handle"
					>
						<template #item="{ element }">
							<div class="bg-slate-200 p-4">
								<div class="p-2 flex grid-cols-3 gap-4">
									<Icon.AlignJustify class="handle" />
									<div class="w-full">{{ element.name }}</div>
									<Icon.Trash
										class="m-auto"
										@click="menuRemoveCategory(element)"
									/>
									<Icon.Plus @click="menuAddProduct(element)" />
								</div>
								<draggable
									v-model="element.menu_items"
									:group="element.name"
									@start="menuItemsDrag = true"
									@end="menuProductSavePositions(element.id)"
									item-key="id"
									handle=".handle"
								>
									<template #item="{ element }">
										<div
											class="bg-slate-100 p-4 flex grid-cols-4 gap-4 shadow-md"
										>
											<div class="flex">
												<Icon.AlignJustify class="handle m-auto" />
											</div>
											<ImageComponentAdmin
												@click="showProductDetail(element)"
												:image_url="element.image_url"
												:store_id="store.id"
												:name="element.id"
												class="w-12 h-12"
											/>
											<div
												class="flex-auto"
												@click="showProductDetail(element)"
											>
												{{ element.name }}<br />{{ toCurrency(element.price) }}
												<span
													class="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-red-200 text-red-700 rounded-3xl"
													v-if="!element.available"
													>indisponível</span
												>
											</div>
											<div class="flex">
												<Icon.Trash
													class="m-auto"
													@click="menuRemoveProduct(element)"
												/>
											</div>
										</div>
									</template>
								</draggable>
							</div>
						</template>
					</draggable>

					<div class="flex justify-end">
						<button
							@click="menuTabSave"
							class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Gravar
						</button>
					</div>
				</div>
			</div>

			<div id="tabImages" class="hidden tab-content text-gray-700 min-h-60">
				<h2 class="font-bold my-4">Images</h2>

				<div
					v-if="imagesLoading"
					class="w-full flex items-center justify-center p-40"
				>
					<div
						class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
						role="status"
					>
						<span
							class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
							>Loading...</span
						>
					</div>
				</div>

				<div v-if="!imagesLoading">
					<div class="border border-default-200 rounded-lg">
						<div class="border-b border-default-200 px-6 py-5">
							<h4 class="text-lg font-medium text-default-800">Nova imagem</h4>
						</div>
						<div
							class="border-b border-default-200 px-6 py-5 grid md:grid-cols-3 grid-cols-1 gap-4"
						>
							<div class="">
								<div class="input-group col-4">
									<input
										type="text"
										name="name"
										v-model="imagesNewName"
										placeholder="Nome da imagem"
										class="block w-full rounded-lg py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
									/>
								</div>
							</div>
							<div class="">
								<input
									type="file"
									name="image"
									ref="imagesNewFile"
									@change="imagesAddImageChanged"
									class="w-full text-gray-500 font-medium text-sm bg-gray-100 file:px-6 file:py-3 file:rounded-3xl file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-primary file:hover:bg-primary file:text-white rounded"
								/>
							</div>
							<div class="">
								<button
									@click="imagesAddImage"
									class="relative inline-flex items-center justify-center px-6 py-3 rounded-3xl text-base bg-primary text-white capitalize transition-all hover:bg-primary"
								>
									Upload
								</button>
							</div>
						</div>
					</div>

					<div
						class="xl:order-1 order-2 max-w-screen-lg rounded-md border border-gray-100 text-gray-600 shadow-md"
						v-for="image in images"
						:key="image.id"
					>
						<image-upload
							class="h-24"
							:name="image.name"
							prefix="merchant"
							:store_id="store.id"
							:maxSize="5"
							accept="png,jpg,jpeg"
							@file-uploaded="uploadImage"
						/>
						{{ image.name }}
						<div class="flex">
							<Icon.Trash class="m-auto" @click="imagesRemove(image)" />
						</div>
					</div>
				</div>
			</div>

			<div id="tabDelivery" class="hidden tab-content text-gray-700 min-h-60">
				<h2 class="font-bold my-4">Delivery</h2>
				<p class="">
					Para realizar a integração com a UberEats, basta inserir o ID da loja
					na UberEats no campo abaixo e carregar no botão "Realizar integração"
				</p>

				<div class="grid grid-cols-2 gap-4 my-6">
					<input
						type="text"
						placeholder="Id Loja UberEats"
						class="block w-full bg-transparent rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						v-model="deliveryUberEatsStoreId"
					/>

					<button
						@click="startUberEatsIntegration"
						class="px-6 py-3 rounded-3xl bg-black text-white capitalize transition-all hover:bg-black-500"
					>
						Realizar Integração
					</button>
				</div>

				<div v-if="deliveryUberEatsStoreError != ''" class="text-red-600">
					{{ deliveryUberEatsStoreError }}
				</div>
			</div>
		</div>
	</div>

	<div
		v-if="menuPopupOpened"
		class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
		id="modal-product"
	>
		<div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
		<div
			class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
		>
			<!--content-->
			<div>
				<Icon.X
					@click="menuClosePopup"
					class="cursor-pointer absolute top-1 right-1"
				/>
				<!--body-->
				<div class="text-center gap-4 p-5 flex-auto justify-center">
					<div class="mb-4" v-if="currentProductData.id == ''">
						Novo produto
					</div>
					<div class="mb-4" v-else>Editar produto</div>
					<input
						id="name"
						name="name"
						class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						type="text"
						placeholder="Nome"
						v-model="currentProductData.name"
					/>
					<div class="text-center grid grid-cols-2 gap-4 p-5 justify-center">
						<div>
							<label class="">Foto</label>
							<image-upload
								v-if="currentProductData.id != ''"
								class="h-24"
								:name="currentProductData.id"
								:product="currentProductData"
								:store_id="store.id"
								:maxSize="5"
								accept="png,jpg,jpeg"
								@file-uploaded="uploadImage"
							/>
						</div>
						<div class="mb-4">
							<label for="description" class="">Descrição</label>
							<textarea
								id="description"
								name="description"
								rows="5"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								placeholder="Descrição"
								v-model="currentProductData.description"
							/>
						</div>

						<div class="mb-4">
							<label for="price" class="">Preço</label>
							<input
								id="price"
								name="price"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								placeholder="Preço"
								v-model="currentProductData.price"
							/>
						</div>

						<div class="mb-4">
							<label for="integration_id" class="">Integration Id</label>

							<input
								type="text"
								id="integration_id"
								v-model="currentProductData.integration_id"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
							/>
						</div>

						<div class="mb-4">
							<label for="availible" class="">Em estoque?</label>

							<input
								type="checkbox"
								id="availible"
								v-model="currentProductData.available"
								true-value="1"
								false-value="0"
								class="relative w-[3.25rem] h-7 bg-default-200 focus:ring-0 checked:bg-none checked:!bg-primary border-2 border-transparent rounded-3xl cursor-pointer transition-colors ease-in-out duration-200 appearance-none focus:ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-3xl before:transform before:transition before:ease-in-out before:duration-200"
							/>
						</div>
					</div>
					<div class="flex justify-end">
						<button
							@click="menuProductSave"
							class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Gravar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		v-if="menuCategoryPopupOpened"
		class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
		id="modal-category"
	>
		<div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
		<div
			class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
		>
			<!--content-->
			<div>
				<Icon.X
					@click="menuCategoryClosePopup"
					class="cursor-pointer absolute top-1 right-1"
				/>
				<!--body-->
				<div class="text-center gap-4 p-5 flex-auto justify-center">
					<div class="mb-4" v-if="currentCategoryData.id == ''">
						Nova Categoria
					</div>
					<div class="mb-4" v-else>Editar categoria</div>
					<input
						id="name"
						name="name"
						class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						type="text"
						v-model="currentCategoryData.name"
					/>
					<div class="flex justify-end">
						<button
							@click="menuCategorySave"
							class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Gravar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import FormData from "form-data";
import draggable from "vuedraggable";
import _debounce from "lodash/debounce";
</script>

<script>
export default {
	components: {
		draggable,
	},
	props: {
		store: { type: Object },
		companies: { type: Array },
		ubereats_client_id: { type: String, default: "" },
	},
	data() {
		return {
			visibility: false,
			totalOrder: 0,
			progress: null,
			error: null,
			ordersLoading: false,
			serverItemsLength: 0,
			serverOptions: {
				page: 1,
				rowsPerPage: 5,
				sortBy: "age",
				sortType: "desc",
			},
			headers: [
				{ text: "PEDIDO", value: "order_id" },
				{ text: "DIA", value: "date" },
				{ text: "HORA", value: "time" },
				{ text: "CLIENTE", value: "client" },
				{ text: "ENTREGA", value: "deliverymode" },
				{ text: "ESTAFETA", value: "estafeta" },
				{ text: "SUBTOTAL", value: "subtotal" },
			],
			items: [
				{
					order_id: "EC044",
					date: "2024-03-02",
					time: "17:14",
					client: "Vincent",
					deliverymode: "delivery",
					estafeta: "Davidson",
					subtotal: 25.64,
				},
				{
					order_id: "TG09S",
					date: "2024-03-02",
					time: "17:27",
					client: "Davidson",
					deliverymode: "delivery",
					estafeta: "St. Vincent-St. Mary HS (OH)",
					subtotal: 38.1,
				},
				{
					order_id: "ML992",
					date: "2024-03-02",
					time: "17:33",
					client: "Austin",
					deliverymode: "delivery",
					estafeta: "Texas-Austin",
					subtotal: 22.0,
				},
				{
					order_id: "DC553",
					date: "2024-03-02",
					time: "17:54",
					client: "Filathlitikos",
					deliverymode: "delivery",
					estafeta: "Filathlitikos",
					subtotal: 41.34,
				},
			],
			menuLoading: true,
			menuItems: [],
			menuItemsDrag: false,
			menuPopupOpened: false,
			menuCategoryPopupOpened: false,
			currentProduct: {},
			currentProductData: {},
			currentCategory: {},
			currentCategoryData: {},

			imagesLoading: true,
			images: [],
			imagesNewName: "",
			imagesNewFile: null,

			hoursLoading: true,
			hoursItems: [],

			deliveryUberEatsStoreId:
				this.store.config.find((cfg) => cfg.name == "UBEREATS_STORE_ID_TEMP")
					?.content ?? "",
			deliveryUberEatsStoreError: "",
		};
	},
	mounted() {
		const tabs = document.querySelectorAll("[data-tab-target]");
		const activeClass = "border-blue-600";

		// Select first tab by default
		tabs[0].classList.add(activeClass);
		document.querySelector("#tabConfig").classList.remove("hidden");

		tabs.forEach((tab) => {
			tab.addEventListener("click", () => {
				const targetContent = document.querySelector(tab.dataset.tabTarget);

				document
					.querySelectorAll(".tab-content")
					.forEach((content) => content.classList.add("hidden"));
				targetContent.classList.remove("hidden");

				// Remove active class from all tabs
				document
					.querySelectorAll(".border-blue-600")
					.forEach((activeTab) => activeTab.classList.remove(activeClass));

				// Add active class to clicked tab
				tab.classList.add(activeClass);

				this.enableTab(targetContent.id);
			});
		});
	},
	created() {},
	methods: {
		onCartUpdated(data) {
			this.visibility = data.itemsCount > 0;
			this.totalOrder = data.total;
		},
		enableTab(tabName) {
			if (tabName == "tabMenu") {
				this.menuLoad();
			} else if (tabName == "tabHours") {
				this.hoursLoad();
			} else if (tabName == "tabImages") {
				this.imagesLoad();
			}
		},
		uploadLogo(file, dom) {
			let data = new FormData();
			data.append("name", "logo");
			data.append("image", file);

			axios
				.post("/merchant/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				})
				.finally((error) => {
					if (this.$refs.logoUpload) {
						this.$refs.logoUpload.reload();
					}
				});
		},
		uploadHomeBackground(file, dom) {
			let data = new FormData();
			data.append("name", "bg-home");
			data.append("image", file);

			axios
				.post("/merchant/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				})
				.finally((error) => {
					if (this.$refs.bgUpload) {
						this.$refs.bgUpload.reload();
					}
				});
		},
		uploadImage(file, name, product_id, dom) {
			let data = new FormData();
			data.append("name", name);
			data.append("product_id", product_id);
			data.append("image", file);

			axios
				.post("/merchant/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				})
				.finally((error) => {
					if (dom) {
						var dt = new Date();
						if (dom.src.length >= 0) {
							dom.src = dom.src + "?" + dt.getTime();
						}
					}
				});
		},
		settingsTabSave(submitEvent) {
			axios
				.post("/merchant/store/" + this.store.id + "/settings/update", {
					name: submitEvent.target.elements.name.value,
					email: submitEvent.target.elements.email.value,
					phone: submitEvent.target.elements.phone.value,
					url: submitEvent.target.elements.url.value,
					home_header: submitEvent.target.elements.home_header.value,
					pause_new_orders:
						submitEvent.target.elements.pause_new_orders.checked,
					address: submitEvent.target.elements.address.value,
					address_number: submitEvent.target.elements.address_number.value,
					appartment_number:
						submitEvent.target.elements.appartment_number.value,
					zipcode: submitEvent.target.elements.zipcode.value,
					city: submitEvent.target.elements.city.value,
					state: submitEvent.target.elements.state.value,
					country: submitEvent.target.elements.country.value,
					lat: submitEvent.target.elements.lat.value,
					lng: submitEvent.target.elements.lng.value,
					minutes_to_prepare:
						submitEvent.target.elements.minutes_to_prepare.value,
					max_distance_to_delivery:
						submitEvent.target.elements.max_distance_to_delivery.value,
				})
				.then(() => {
					alert("updated!");
				})
				.catch((error) => {
					console.log(error);
				});
		},
		ordersLoad() {
			async () => {
				this.ordersLoading = true;

				axios
					.get("/merchant/store/" + this.store.id + "/orders/list")
					.then(() => {
						items.value = serverCurrentPageItems;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.ordersLoading = false;
					});

				const { serverCurrentPageItems, serverTotalItemsLength } =
					await mockServerItems(serverOptions.value);
			};
		},
		menuLoad() {
			const self = this;
			this.menuLoading = true;
			self.menuItems = [];

			axios
				.get("/merchant/store/" + this.store.id + "/menu")
				.then((response) => {
					self.menuItems = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.menuLoading = false;
				});
		},
		showProductDetail(product) {
			this.menuPopupOpened = true;

			this.currentProduct = product;
			this.currentProductData = Object.assign({}, product);
		},
		menuAddCategory(element) {
			this.currentCategoryData = {
				id: "",
				name: "",
			};
			this.menuCategoryPopupOpened = true;
		},
		menuAddProduct(element) {
			this.currentProductData = {
				available: 1,
				description: "",
				id: "",
				menu_category_id: element.id,
				name: "",
				price: "",
				size: null,
				uom: null,
			};
			this.menuPopupOpened = true;
		},
		menuRemoveProduct(element) {
			let self = this;
			axios
				.post("/merchant/store/" + this.store.id + "/menuitem/delete", {
					id: element.id,
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.menuLoad();
				});
		},
		menuRemoveCategory(element) {
			let self = this;
			axios
				.post("/merchant/store/" + this.store.id + "/menucategory/delete", {
					id: element.id,
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.menuLoad();
				});
		},
		menuProductSave() {
			this.currentProduct.name = this.currentProductData.name;
			this.currentProduct.description = this.currentProductData.description;
			this.currentProduct.price = this.currentProductData.price;
			this.currentProduct.available = this.currentProductData.available
				? "1"
				: "0";

			axios
				.post("/merchant/store/" + this.store.id + "/menuitem", {
					id: this.currentProductData.id,
					name: this.currentProductData.name,
					description: this.currentProductData.description,
					price: this.currentProductData.price,
					menu_category_id: this.currentProductData.menu_category_id,
					order: this.currentProductData.order,
					available: this.currentProductData.available,
				})
				.catch((error) => {
					console.log(error);
				});

			this.menuClosePopup();
		},
		menuProductSavePositions(menucategory_id) {
			const self = this;

			this.menuItems.forEach((category, categoryIndex) => {
				category.order = categoryIndex;
				category.menu_items.forEach((product, productIndex) => {
					product.order = productIndex;
				});
			});

			let data = [];

			if (menucategory_id == undefined) {
				this.menuItems.forEach((category, categoryIndex) => {
					data.push({
						id: category.id,
						name: category.name,
						order: category.order,
					});
				});

				axios
					.post("/merchant/store/" + this.store.id + "/menupositions", {
						elements: data,
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						self.menuLoad();
					});
			} else {
				this.menuItems
					.filter((c) => c.id == menucategory_id)[0]
					.menu_items.forEach((product, productIndex) => {
						data.push({
							id: product.id,
							order: product.order,
						});
					});

				axios
					.post("/merchant/store/" + this.store.id + "/menuitempositions", {
						elements: data,
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						self.menuLoad();
					});
			}
		},
		menuCategorySave() {
			this.currentCategory.name = this.currentCategoryData.name;

			axios
				.post("/merchant/store/" + this.store.id + "/menucategory", {
					id: this.currentCategoryData.id,
					name: this.currentCategoryData.name,
				})
				.catch((error) => {
					console.log(error);
				});

			this.menuCategoryClosePopup();
		},
		menuCategoryClosePopup() {
			this.menuCategoryPopupOpened = false;
			this.menuLoad();
		},
		menuClosePopup() {
			this.menuPopupOpened = false;
			this.menuLoad();
		},
		menuTabSave() {
			this.menuItems.forEach((category, categoryIndex) => {
				category.order = categoryIndex;
				category.menu_items.forEach((product, productIndex) => {
					product.order = productIndex;
				});
			});
		},

		//Hours
		hoursLoad() {
			const self = this;
			this.hoursLoading = true;

			axios
				.get("/merchant/store/" + this.store.id + "/hours")
				.then((response) => {
					self.hoursItems = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.hoursLoading = false;
				});
		},
		hoursAdd() {
			const self = this;
			this.hoursLoading = true;

			axios
				.post("/merchant/store/" + this.store.id + "/hours", {
					hour_start: "11:00",
					hour_end: "23:00",
					monday: true,
					tuesday: true,
					wednesday: true,
					thursday: true,
					friday: true,
					saturday: true,
					sunday: true,
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.hoursLoad();
				});
		},

		hourWeek(storeHour, week, enabled) {
			const self = this;
			this.hoursLoading = true;

			switch (week) {
				case "monday":
					storeHour.monday = enabled;
					break;
				case "tuesday":
					storeHour.tuesday = enabled;
					break;
				case "wednesday":
					storeHour.wednesday = enabled;
					break;
				case "thursday":
					storeHour.thursday = enabled;
					break;
				case "friday":
					storeHour.friday = enabled;
					break;
				case "saturday":
					storeHour.saturday = enabled;
					break;
				case "sunday":
					storeHour.sunday = enabled;
					break;
			}

			axios
				.post("/merchant/store/" + this.store.id + "/hours", storeHour)
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.hoursLoad();
				});
		},
		updateStartInternalValue(hour, event) {
			this.touched = true;
			this.updateStartValue(hour, event.target.value);
		},
		updateStartValue: _debounce(function (hour, value) {
			this.touched = false;
			hour.hour_start = value;
			axios
				.post("/merchant/store/" + this.store.id + "/hours", hour)
				.catch((error) => {
					console.log(error);
				});
		}, 600),
		updateEndInternalValue(hour, event) {
			this.touched = true;
			this.updateEndValue(hour, event.target.value);
		},
		updateEndValue: _debounce(function (hour, value) {
			this.touched = false;
			hour.hour_end = value;

			axios
				.post("/merchant/store/" + this.store.id + "/hours", hour)
				.catch((error) => {
					console.log(error);
				});
		}, 600),

		hoursAdd() {
			const self = this;
			this.hoursLoading = true;

			axios
				.post("/merchant/store/" + this.store.id + "/hours", {
					hour_start: "11:00",
					hour_end: "23:00",
					monday: true,
					tuesday: true,
					wednesday: true,
					thursday: true,
					friday: true,
					saturday: true,
					sunday: true,
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.hoursLoad();
				});
		},

		removeHour(storeHour) {
			const self = this;

			axios
				.post("/merchant/store/" + this.store.id + "/hours/delete", storeHour)
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.hoursLoad();
				});
		},

		//Images
		imagesLoad() {
			const self = this;
			this.imagesLoading = true;

			axios
				.get("/merchant/store/" + this.store.id + "/images")
				.then((response) => {
					self.images = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.imagesLoading = false;
				});
		},

		imagesRemove(image) {
			const self = this;
			this.imagesLoading = true;

			axios
				.post("/merchant/store/" + this.store.id + "/image/delete", {
					id: image.id,
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					self.imagesLoad();
				});
		},

		imagesAddImageChanged(event) {
			this.imagesNewFile = event.target.files[0];
		},

		imagesAddImage() {
			let self = this;
			self.imagesLoading = true;

			let data = new FormData();
			data.append("name", this.imagesNewName);
			data.append("image", this.imagesNewFile);

			this.imagesNewName = "";
			this.$refs.imagesNewFile.value = "";

			axios
				.post("/merchant/images/upload/" + this.store.id, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.catch((error) => {
					console.log(error);
				})
				.finally((error) => {
					self.imagesLoad();
				});
		},

		// DELIVERY
		startUberEatsIntegration() {
			this.deliveryUberEatsStoreError = "";

			if (
				this.deliveryUberEatsStoreId == null ||
				this.deliveryUberEatsStoreId == "" ||
				this.deliveryUberEatsStoreId.length < 30 ||
				this.deliveryUberEatsStoreId.length > 50
			) {
				this.deliveryUberEatsStoreId = "";
				this.deliveryUberEatsStoreError =
					"Insira corretamente o ID da sua loja na UberEats";
				return;
			}

			console.log(
				"https://login.uber.com/oauth/v2/authorize?client_id=" +
					this.ubereats_client_id +
					"&response_type=code&redirect_uri=https://app.eatz.pt/uber/integration/callback%3Fstore%3D" +
					this.store.id +
					"%26uber_store%3D" +
					this.deliveryUberEatsStoreId
			);
			window.open(
				"https://login.uber.com/oauth/v2/authorize?client_id=" +
					this.ubereats_client_id +
					"&response_type=code&redirect_uri=https://app.eatz.pt/uber/integration/callback%3Fstore%3D" +
					this.store.id +
					"%26uber_store%3D" +
					this.deliveryUberEatsStoreId
			);
		},

		toCurrency(value) {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}
			var formatter = new Intl.NumberFormat("pt-PT", {
				style: "currency",
				currency: "EUR",
			});

			return formatter.format(value);
		},
	},
};
</script>
