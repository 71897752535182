<template>
	<a
		:href="link"
		class="relative flex text-base transition-all text-default-600 hover:text-primary"
		:style="styletextcolor"
	>
		<i class="w-5 h-5" data-lucide="shopping-cart"></i>
		<span
			v-if="itemsCount > 0"
			class="absolute z-10 -top-2.5 end-0 inline-flex items-center justify-center p-1 h-5 w-5 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 bg-red-500 rounded-full"
		>
			{{ itemsCount }}
		</span>
	</a>
</template>

<script>
export default {
	props: {
		link: { type: String },
		currentAddress: { type: Object },
		currentCart: { type: Object },
		orderValues: { type: Object },
		textcolor: { type: String, default: "#FFFFFF" },
	},
	computed: {
		styletextcolor: function () {
			return "color: " + this.textcolor;
		},
	},
	data() {
		return {
			orderNumber: "",
			products: [],
			country: "PT",
			deliveryMode: "delivery",
			itemsCount: 0,
			total: 0,
			order: {},
			address: {},
			nif: "",
		};
	},
	mounted() {
		document.Cart = this;

		this.products = this.currentCart.products ?? [];
		this.itemsCount = this.currentCart.productsQtd;
		this.total = this.currentCart.total ?? 0;
		this.country = this.currentCart;
		this.deliveryMode = this.currentCart.deliveryMode ?? "delivery";
		this.order = this.orderValues;
		this.address = this.currentAddress;
	},
	created() {},
	methods: {
		loadCart() {
			axios
				.get("/cart")
				.then((response) => {
					this.products = [];
					for (const [key, value] of Object.entries(
						response.data?.currentCart?.products ?? {}
					)) {
						this.products.push(value);
					}
					this.itemsCount = response.data?.currentCart?.productsQtd;
					this.total = response.data?.orderValues.totalOrder ?? 0;
					this.order = response.data?.orderValues ?? {};
					this.country = response.data?.currentCart?.country ?? "PT";
					this.deliveryMode =
						response.data?.currentCart?.deliveryMode ?? "delivery";
					this.address = response.data?.currentAddress;
					this.nif = response.data?.nif;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() =>
					this.emitter.emit("cart.updated", {
						products: this.products,
						total: this.total,
						itemsCount: this.itemsCount,
						deliveryMode: this.deliveryMode,
						country: this.country,
						orderValues: this.order,
						currentAddress: this.address,
						nif: this.nif,
					})
				);
		},
		updateNIF(nif) {
			this.nif = nif;

			axios
				.post("/cart/nif", {
					nif: nif,
				})
				.then(() => {
					this.loadCart();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		add(productId, qtd = 1, addons = {}, obs) {
			axios
				.post("/cart/add", {
					productId: productId,
					qtd: qtd,
					addons: addons,
					obs: obs,
				})
				.then(() => {
					this.loadCart();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		increase(rowId, qtd = 1) {
			axios
				.post("/cart/increase", {
					rowId: rowId,
					qtd: qtd,
				})
				.then(() => {
					this.loadCart();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		decrease(rowId, qtd = 1) {
			axios
				.post("/cart/decrease", {
					rowId: rowId,
					qtd: qtd,
				})
				.then(() => {
					this.loadCart();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		remove(rowId) {
			axios
				.post("/cart/remove", {
					rowId: rowId,
				})
				.then(() => {
					this.loadCart();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		reset() {
			this.orderNumber = "";
		},
		updateCode() {
			this.orderNumber = this.orderNumber.toUpperCase();
		},
		chanceView() {
			if (this.store === 0) this.store = this.stores[0].id ?? 0;
		},
		started() {
			this.$root.$root("started", {
				number: this.orderNumber,
				store: this.store,
			});
			this.reset();
		},
	},
};
</script>
