<template>
	<div class="grid grid-col-1 gap-4">
		<div class="">
			<div class="grid md:grid-cols-2 grid-cols-1 gap-4">
				<div class="">
					<label class="block text-sm font-medium text-default-900 mb-2"
						>Restaurantes</label
					>
					<select
						v-model="currentStore"
						@input="updateStore($event.target.value)"
						class="block w-full bg-transparent text-sm rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
					>
						<option value="*">Todas ({{ stores.length }})</option>
						<option v-for="store in stores" :key="store.id" :value="store.id">
							{{ store.name }}
						</option>
					</select>
				</div>
				<div class="">
					<label class="block text-sm font-medium text-default-900 mb-2"
						>Período</label
					>
					<select
						v-model="currentPeriod"
						@input="updatePeriod($event.target.value)"
						class="block w-full bg-transparent text-sm rounded-3xl border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
					>
						<option
							v-for="period in periods"
							:key="period.label"
							:value="period.id"
						>
							{{ period.label }}
						</option>
					</select>
				</div>
			</div>
		</div>

		<Loading :enabled="loading" />

		<div v-if="!loading" class="gap-4 grid grid-cols-1">
			<div>
				<h2 class="text-lg">Estafetas ({{ drivers.length }})</h2>
				<div class="flex flex-wrap gap-4">
					<table
						class="table-auto border-spacing-2 border-collapse border border-slate-500"
					>
						<thead>
							<tr>
								<th class="border border-slate-700 px-2 py-1">Entregas</th>
								<th class="border border-slate-700 px-2 py-1">Nome</th>
								<th class="border border-slate-700 px-2 py-1">Valor</th>
								<th class="border border-slate-700 px-2 py-1">IVA</th>
								<th class="border border-slate-700 px-2 py-1">IBAN</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="driver in drivers" :key="driver.driver_id">
								<td class="border border-slate-700 px-2 py-1">
									{{ driver.orders }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ driver.driver.name }} {{ driver.driver.lastname }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(driver.amount) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(driver.amount * 0.23) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ driver.driver.iban }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<h2 class="text-lg">Frota</h2>
				<div class="flex flex-wrap gap-4">
					<table
						class="table-auto border-spacing-2 border-collapse border border-slate-500"
					>
						<thead>
							<tr>
								<th class="border border-slate-700 px-2 py-1">Frotista</th>
								<th class="border border-slate-700 px-2 py-1">Entregas</th>
								<th class="border border-slate-700 px-2 py-1">
									Total Estafetas
								</th>
								<th class="border border-slate-700 px-2 py-1">Lucro</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="fleet in frota" :key="fleet.id">
								<td class="border border-slate-700 px-2 py-1">
									{{ fleet.name }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ fleet.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(fleet.driver_amount ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(fleet.amount ?? 0) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<h2 class="text-lg">Plataformas de Entrega</h2>
				<div class="flex flex-wrap gap-4">
					<table
						class="table-auto border-spacing-2 border-collapse border border-slate-500"
					>
						<thead>
							<tr>
								<th class="border border-slate-700 px-2 py-1">Plataforma</th>
								<th class="border border-slate-700 px-2 py-1">Entregas</th>
								<th class="border border-slate-700 px-2 py-1">Custo</th>
								<th class="border border-slate-700 px-2 py-1">Recebido</th>
								<th class="border border-slate-700 px-2 py-1">Lucro</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="border border-slate-700 px-2 py-1">Eatz</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ eatz?.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(eatz?.delivery_cost ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(eatz?.amount ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{
										toCurrency((eatz?.amount ?? 0) - (eatz?.delivery_cost ?? 0))
									}}
								</td>
							</tr>
							<tr>
								<td class="border border-slate-700 px-2 py-1">Glovo</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ glovo?.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(glovo?.delivery_cost ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(glovo?.amount ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{
										toCurrency(
											(glovo?.amount ?? 0) - (glovo?.delivery_cost ?? 0)
										)
									}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<h2 class="text-lg">Pedidos</h2>
				<div class="flex flex-wrap gap-4">
					<table
						class="table-auto border-spacing-2 border-collapse border border-slate-500"
					>
						<thead>
							<tr>
								<th class="border border-slate-700 px-2 py-1">Tipo</th>
								<th class="border border-slate-700 px-2 py-1">Pedidos</th>
								<th class="border border-slate-700 px-2 py-1">Total Vendas</th>
								<th class="border border-slate-700 px-2 py-1">Taxas</th>
								<th class="border border-slate-700 px-2 py-1">Custo Entrega</th>
								<th class="border border-slate-700 px-2 py-1">Lucro</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="border border-slate-700 px-2 py-1">TakeAway</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ takeaway?.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(takeaway?.sales ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(takeaway?.platform_fee ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(takeaway?.delivery_cost ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{
										toCurrency(
											(takeaway?.platform_fee ?? 0) -
												(takeaway?.delivery_cost ?? 0)
										)
									}}
								</td>
							</tr>
							<tr>
								<td class="border border-slate-700 px-2 py-1">Delivery</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ delivery?.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(delivery?.sales ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(delivery?.platform_fee ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(delivery?.delivery_cost ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{
										toCurrency(
											(delivery?.platform_fee ?? 0) -
												(delivery?.delivery_cost ?? 0)
										)
									}}
								</td>
							</tr>
							<tr>
								<td class="border border-slate-700 px-2 py-1">
									Delivery UberEats
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ deliveryUber?.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(deliveryUber?.sales ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(deliveryUber?.platform_fee ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(deliveryUber?.delivery_cost ?? 0) }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{
										toCurrency(
											(deliveryUber?.platform_fee ?? 0) -
												(deliveryUber?.delivery_cost ?? 0)
										)
									}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<h2 class="text-lg">Taxas de Serviço</h2>
				<div class="flex flex-wrap gap-4">
					<table
						class="table-auto border-spacing-2 border-collapse border border-slate-500"
					>
						<thead>
							<tr>
								<th class="border border-slate-700 px-2 py-1">Pedidos</th>
								<th class="border border-slate-700 px-2 py-1">
									Taxas de Serviço
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="border border-slate-700 px-2 py-1">
									{{ serviceFee?.orders ?? 0 }}
								</td>
								<td class="border border-slate-700 px-2 py-1">
									{{ toCurrency(serviceFee?.amount ?? 0) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div>
				<h2 class="text-lg">Resultado</h2>
				<div class="flex flex-wrap gap-4">
					<div><strong>Pedidos:</strong> {{ result.orders ?? 0 }}</div>
					<div>
						<strong>Vendas:</strong> {{ toCurrency(result.sales ?? 0) }}
					</div>
					<div>
						<strong>Custo de entrega:</strong>
						{{ toCurrency(result.delivery_cost ?? 0) }}
					</div>
					<div><strong>Lucro:</strong> {{ toCurrency(result.net ?? 0) }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from "moment";
import OrderCard from "./OrderCard.vue";
import Loading from "../Loading.vue";
</script>

<script>
export default {
	components: {
		card: OrderCard,
	},
	props: {
		stores: { type: Array },
	},
	data() {
		return {
			loading: true,
			currentStore: "",
			currentPeriod: "",
			frota: [],
			deliveryUber: {},
			delivery: {},
			takeaway: {},
			eatz: {},
			glovo: {},
			serviceFee: {},
			result: {},
			drivers: [],
			data: {
				sales: 0,
				new: 0,
				net: 0,
				orders: 0,
				ticket: 0,
				prepare_avg: 0,
				delivery_avg: 0,
			},
			periods: [
				{
					id: 0,
					label: "Hoje",
					start: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
					end: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 1,
					label:
						moment().startOf("isoWeek").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").format("DD/MM/YYYY"),
					start: moment().startOf("isoWeek").format("YYYY-MM-DD HH:mm:ss"),
					end: moment().endOf("isoWeek").format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 2,
					label:
						moment().startOf("isoWeek").add(-7, "d").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").add(-7, "d").format("DD/MM/YYYY"),
					start: moment()
						.startOf("isoWeek")
						.add(-7, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
					end: moment()
						.endOf("isoWeek")
						.add(-7, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 3,
					label:
						moment().startOf("isoWeek").add(-14, "d").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").add(-14, "d").format("DD/MM/YYYY"),
					start: moment()
						.startOf("isoWeek")
						.add(-14, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
					end: moment()
						.endOf("isoWeek")
						.add(-14, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 4,
					label:
						moment().startOf("isoWeek").add(-21, "d").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").add(-21, "d").format("DD/MM/YYYY"),
					start: moment()
						.startOf("isoWeek")
						.add(-21, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
					end: moment()
						.endOf("isoWeek")
						.add(-21, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 5,
					label:
						moment().startOf("isoWeek").add(-28, "d").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").add(-28, "d").format("DD/MM/YYYY"),
					start: moment()
						.startOf("isoWeek")
						.add(-28, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
					end: moment()
						.endOf("isoWeek")
						.add(-28, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 6,
					label:
						moment().startOf("isoWeek").add(-35, "d").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").add(-35, "d").format("DD/MM/YYYY"),
					start: moment()
						.startOf("isoWeek")
						.add(-35, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
					end: moment()
						.endOf("isoWeek")
						.add(-35, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
				},
				{
					id: 7,
					label:
						moment().startOf("isoWeek").add(-42, "d").format("DD/MM/YYYY") +
						" - " +
						moment().endOf("isoWeek").add(-42, "d").format("DD/MM/YYYY"),
					start: moment()
						.startOf("isoWeek")
						.add(-42, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
					end: moment()
						.endOf("isoWeek")
						.add(-42, "d")
						.format("YYYY-MM-DD HH:mm:ss"),
				},
			],
		};
	},
	mounted() {
		this.currentStore = "*";
		this.currentPeriod = this.periods[0]?.id ?? "";

		this.loadData();
	},
	methods: {
		loadData() {
			const self = this;
			this.loading = true;

			axios
				.post("/admin/finance", {
					store: this.currentStore,
					start: this.periods[this.currentPeriod].start,
					end: this.periods[this.currentPeriod].end,
				})
				.then((response) => {
					self.orders = response.data.orders;

					self.drivers = response.data.drivers;
					self.frota = response.data.frota;
					self.deliveryUber = response.data.deliveryUber;
					self.delivery = response.data.delivery;
					self.takeaway = response.data.takeaway;
					self.eatz = response.data.eatz;
					self.glovo = response.data.glovo;
					self.serviceFee = response.data.serviceFee;
					self.result = response.data.result;

					self.orders_new = self.orders.filter(
						(order) => order.status == "NEW"
					);
					self.orders_ready = self.orders.filter(
						(order) => order.status == "READY"
					);
					self.orders_delivering = self.orders.filter(
						(order) => order.status == "ON_DELIVERY"
					);
					self.orders_done = self.orders.filter(
						(order) => order.status == "DELIVERED"
					);
				})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		updateStore(store) {
			this.currentStore = store;
			this.loadData();
		},

		updatePeriod(period) {
			this.currentPeriod = period;
			this.loadData();
		},

		toCurrency(value) {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}
			var formatter = new Intl.NumberFormat("pt-PT", {
				style: "currency",
				currency: "EUR",
			});

			return formatter.format(value);
		},
	},
};
</script>
