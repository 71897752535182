<template>
	<section class="md:container lg:grid lg:grid-cols-4 gap-4 flex-grow aaa">
		<div
			class="z-10 top-20 lg:block lg:backdrop-blur-none backdrop-blur-md lg:bg-transparent bg-white/40 md:w-auto w-full fixed"
			id="all_categories"
		>
			<h2
				class="hs-collapse-toggle hidden lg:inline-flex py-4 justify-between items-center gap-2 transition-all uppercase font-medium text-lg text-default-900 w-full open"
			>
				Menu
			</h2>
			<div class="w-full overflow-hidden transition-[height] duration-300 open">
				<div
					class="flex w-full overflow-x-auto flex-row lg:flex-col menuscroll whitespace-nowrap lg:py-0 py-4"
				>
					<div
						class="flex items-center menu-item"
						v-if="promotions?.length > 0"
						scroll-ref="promo"
					>
						<a
							href="#promotions"
							class="ps-3 inline-flex items-center text-default-600 text-sm select-none scrollcategory"
							for="all"
							>Promoções</a
						>
					</div>
					<div
						class="flex items-center menu-item"
						v-for="category in productsFiltred"
						:key="category.id"
						:scroll-ref="category.id"
					>
						<a
							:href="'#' + category.name"
							class="ps-3 inline-flex items-center text-default-600 text-sm select-none scrollcategory"
							for="all"
							>{{ category.name }}</a
						>
					</div>
				</div>
			</div>
			<!-- end category -->
		</div>

		<div class="z-10 top-28 h-0 hidden" id="all_categories2">&nbsp;</div>

		<div class="col-span-4 lg:col-span-3" id="products">
			<div class="w-4/4">
				<div class="input-group text-sm block relative h-8 p-2">
					<div class="absolute w-min h-min right-0 z-10">
						<input
							type="text"
							placeholder="Pesquisar"
							@input="updateSearchValue"
							v-model="search"
							class="block rounded-3xl right-0 top-0 py-0 px-2 text-sm bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
						/>

						<Icon.CircleX
							:size="18"
							v-if="search != ''"
							@click="removeFilter"
							class="absolute right-1 z-10 cursor-pointer"
							style="top: 2px"
						/>
					</div>
				</div>
				<div
					v-if="promotions?.length > 0"
					class="category-group"
					scroll-ref="promo"
				>
					<h2
						class="mt-2 mx-4 relative select-none cursor-pointer font-medium text-lg"
						v-on:click="toggle('promo')"
					>
						Promoções
					</h2>

					<div class="grid md:grid-cols-2 sm:grid-cols-2 gap-1" id="promo">
						<div
							class="xl:order-1 order-2 max-w-screen-lg rounded-md border border-gray-100 text-gray-600 shadow-md"
							v-for="promotion in promotions"
							:key="promotion.id"
						>
							<div
								class="grid grid-cols-8 h-full text-gray-600"
								v-if="promotion.product.image_url"
							>
								<div class="p-4 col-span-5 text-xs">
									<div
										class="h-8 line-clamp-2 text-xs text-wrap whitespace-nowrap items-center justify-between text-default-800 font-semibold cursor-pointer"
										v-on:click="add(promotion.product, 1, true)"
									>
										{{ promotion.product.name }}
									</div>
									<p
										class="mt-3 h-8 line-clamp-2 font-sans text-xs text-wrap whitespace-nowrap"
									>
										{{ promotion.product.description }}
									</p>
									<div class="flex flex-col">
										<p class="mt-2 font-black text-sm">
											{{ toCurrency(promotion.product.price) }}
										</p>
									</div>
								</div>
								<div
									class="relative items-center p-2 text-lg text-black col-span-3"
								>
									<ImageComponent
										:name="promotion.product.id"
										:image_url="promotion.product.image_url"
										class="w-full h-full cursor-pointer"
										v-on:click="add(promotion.product, 1, true)"
									/>
									<div
										class="absolute left-2 right-2 top-2 items-center p-2 text-lg text-black col-span-3"
									>
										<div
											v-if="promotion.type == 'BOGO'"
											class="w-full text-center z-10 absolute py-1 px-1 top-0 right-0 rounded-lg bg-[#f5c638E6] text-white md:text-xs text-[10px] md:leading-4 leading-3"
										>
											Compre um, ganhe outro
										</div>
										<div
											v-else-if="promotion.type == 'DISCOUNT_ITEM'"
											class="w-full text-center z-10 absolute py-1 px-1 top-0 right-0 rounded-lg bg-[#f5c638E6] text-white md:text-xs text-[10px] md:leading-4 leading-3"
										>
											30% OFF
										</div>
									</div>

									<div class="relative h-8 ml-2 mb-2">
										<product-selector
											:product="product_grouped"
											:add="add"
											:remove="remove"
											v-if="currentStoreOpened"
										/>
									</div>
								</div>
							</div>
							<div
								class="grid grid-cols-1 h-full text-gray-600 flex-row"
								v-if="!promotion.product.image_url"
							>
								<div class="p-4 relative w-full">
									<div class="flex flex-col">
										<div
											class="h-8 line-clamp-2 text-xs whitespace-nowrap items-center justify-between text-default-800 font-semibold text-balance cursor-pointer"
											v-on:click="add(promotion.product, 1, true)"
										>
											{{ promotion.product.name }}
										</div>
									</div>
									<p
										class="mt-3 h-8 line-clamp-2 font-sans text-xs text-wrap whitespace-nowrap"
									>
										{{ promotion.product.description }}
									</p>
									<div class="flex flex-col" v-if="!promotion.product.group_id">
										<p class="mt-2 font-black text-sm">
											{{ toCurrency(promotion.product.price) }}
										</p>
									</div>
									<div class="flex flex-col" v-else>
										<div
											class="flex flex-row"
											v-for="product_grouped in category.menu_items
												.filter((p) => p.group_id == promotion.product.group_id)
												.sort(function (a, b) {
													return a.group_order - b.group_order;
												})"
											:key="product_grouped.id"
										>
											<div class="relative">
												<p class="mt-2 text-xs leading-6">
													{{ product_grouped.group_name }}
												</p>
											</div>
											<div class="relative ml-2">
												<p class="mt-2 font-black text-sm leading-6">
													{{ toCurrency(product_grouped.price) }}
												</p>
											</div>
											<div class="relative h-8 ml-2 mb-2">
												<product-selector
													:product="product_grouped"
													:add="add"
													:remove="remove"
													v-if="currentStoreOpened"
												/>
											</div>
										</div>
									</div>

									<div
										class="absolute h-8 right-3 bottom-3"
										v-if="!promotion.product.group_id"
									>
										<product-selector
											:product="promotion.product"
											:add="add"
											:remove="remove"
											v-if="currentStoreOpened"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="my-4" />
				</div>
				<div
					v-for="category in productsFiltred"
					:key="category.id"
					class="category-group"
					:scroll-ref="category.id"
				>
					<h2
						class="mt-2 mx-4 relative select-none cursor-pointer font-medium text-lg"
						:id="category.name"
						v-on:click="toggle(category.id)"
					>
						{{ category.name }}
					</h2>

					<div
						class="grid md:grid-cols-2 sm:grid-cols-2 gap-1"
						:id="category.id"
					>
						<div
							class="xl:order-1 order-2 max-w-screen-lg rounded-md border border-gray-100 text-gray-600 shadow-md"
							v-for="product in category.menu_items
								.filter(
									(value, index, self) =>
										value.group_id === null ||
										index ===
											self.findIndex((t) => t.group_id === value.group_id)
								)
								.sort((p) => p.order)"
							:key="product.id"
						>
							<div
								class="grid grid-cols-8 h-full text-gray-600"
								v-if="product.image_url"
							>
								<div class="p-4 col-span-5 text-xs">
									<div
										class="h-8 line-clamp-2 text-xs text-wrap whitespace-nowrap items-center justify-between text-default-800 font-semibold cursor-pointer"
										v-on:click="add(product, 1, true)"
									>
										{{ product.name }}
									</div>
									<p
										class="mt-3 h-8 line-clamp-2 font-sans text-xs text-wrap whitespace-nowrap"
									>
										{{ product.description }}
									</p>
									<div class="flex flex-col" v-if="!product.group_id">
										<p
											class="mt-2 font-black text-sm"
											v-if="product.hide_price_on_home != true"
										>
											{{ toCurrency(product.price) }}
										</p>
									</div>
									<div class="flex flex-col mt-3" v-else>
										<div
											class="flex flex-row"
											v-for="product_grouped in category.menu_items
												.filter((p) => p.group_id == product.group_id)
												.sort(function (a, b) {
													return a.group_order - b.group_order;
												})"
											:key="product_grouped.id"
										>
											<div class="relative">
												<p class="mt-2 text-xs leading-6">
													{{ product_grouped.group_name }}
												</p>
											</div>
											<div class="relative ml-2">
												<p class="mt-2 font-black text-sm leading-6">
													{{ toCurrency(product_grouped.price) }}
												</p>
											</div>
											<div class="relative h-8 ml-2 mb-2">
												<product-selector
													:product="product_grouped"
													:add="add"
													:remove="remove"
													v-if="currentStoreOpened"
												/>
											</div>
										</div>
									</div>
								</div>
								<div
									class="relative items-center p-2 text-lg text-black col-span-3"
								>
									<ImageComponent
										:name="product.id"
										:image_url="product.image_url"
										class="w-full h-full cursor-pointer"
										v-on:click="add(product, 1, true)"
									/>

									<div
										class="absolute h-8 right-3 bottom-3"
										v-if="!product.group_id"
									>
										<product-selector
											:product="product"
											:add="add"
											:remove="remove"
											v-if="currentStoreOpened"
										/>
									</div>
								</div>
							</div>
							<div
								class="grid grid-cols-1 h-full text-gray-600 flex-row"
								v-if="!product.image_url"
							>
								<div class="p-4 relative w-full">
									<div class="flex flex-col">
										<div
											class="h-8 line-clamp-2 text-xs whitespace-nowrap items-center justify-between text-default-800 font-semibold text-balance cursor-pointer"
											v-on:click="add(product, 1, true)"
										>
											{{ product.name }}
										</div>
									</div>
									<p
										class="mt-3 h-8 line-clamp-2 font-sans text-xs text-wrap whitespace-nowrap"
									>
										{{ product.description }}
									</p>
									<div class="flex flex-col" v-if="!product.group_id">
										<p class="mt-2 font-black text-sm">
											{{ toCurrency(product.price) }}
										</p>
									</div>
									<div class="flex flex-col" v-else>
										<div
											class="flex flex-row"
											v-for="product_grouped in category.menu_items
												.filter((p) => p.group_id == product.group_id)
												.sort(function (a, b) {
													return a.group_order - b.group_order;
												})"
											:key="product_grouped.id"
										>
											<div class="relative">
												<p class="mt-2 text-xs leading-6">
													{{ product_grouped.group_name }}
												</p>
											</div>
											<div class="relative ml-2">
												<p class="mt-2 font-black text-sm leading-6">
													{{ toCurrency(product_grouped.price) }}
												</p>
											</div>
											<div class="relative h-8 ml-2 mb-2">
												<product-selector
													:product="product_grouped"
													:add="add"
													:remove="remove"
													v-if="currentStoreOpened"
												/>
											</div>
										</div>
									</div>

									<div
										class="absolute h-8 right-3 bottom-3"
										v-if="!product.group_id"
									>
										<product-selector
											:product="product"
											:add="add"
											:remove="remove"
											v-if="currentStoreOpened"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="my-4" />
				</div>
				<div
					v-if="itemsCount > 0 && currentStoreOpened"
					class="fixed bottom-10 left-1/2 transform -translate-x-1/2 text-nowrap"
				>
					<a
						href="/carrinho"
						class="flex btn bg-black text-white hover:bg-black-400 gap-2 py-3 px-6 rounded-full shadow-lg items-center justify-center align-middle"
					>
						<Icon.ShoppingBag :size="16" class="inline" />
						<p>
							Finalizar Pedido
							<span v-if="subtotal > 0">{{ toCurrency(subtotal) }}</span>
						</p>
					</a>
				</div>
			</div>
		</div>

		<div
			v-if="productModalOpened"
			class="min-w-screen h-screen animated fadeIn faster md:p-0 p-4 fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
			id="modal-product-addon"
		>
			<div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
			<div
				class="w-full md:w-1/2 relative mx-auto my-auto rounded-xl shadow-lg bg-white h-full md:h-4/5"
			>
				<Icon.X
					@click="productModalClose"
					class="cursor-pointer absolute top-1 right-1 z-40"
				/>
				<!--content-->
				<div class="w-full h-full overflow-y-auto">
					<!--body-->
					<div class="text-center grid grid-cols-1 justify-center pb-10">
						<div
							class="relative items-center w-full h-60"
							v-if="productModal.image_url"
						>
							<ImageComponent
								:name="productModal.id"
								:image_url="productModal.image_url"
								class="w-full h-full"
							/>
						</div>
						<div class="p-4 pb-0 grid grid-cols-1">
							<h2 class="text-lg text-black font-black">
								{{ productModal.name }}
							</h2>
							<p>{{ productModal.description }}</p>

							<div class="grid grid-cols-2">
								<p
									class="my-2 font-black text-left"
									v-if="productModal.hide_price_on_home != true"
								>
									{{ toCurrency(productModal.price) }}
								</p>
								<p
									class="my-2 text-xs text-right"
									v-if="current_addons?.length > 0"
								>
									* itens obrigatórios
								</p>
							</div>
						</div>

						<div
							v-for="addon_group in current_addons"
							:key="addon_group.id"
							class="my-2"
						>
							<div class="grid grid-cols-2 bg-slate-200 p-2">
								<div>
									<div class="font-black text-left">
										{{ addon_group.name }}
										<span v-if="addon_group.min > 0">*</span>
									</div>
									<div class="text-left text-xs" v-if="addon_group.max == 1">
										Selecione até 1 opção
									</div>
									<div class="text-left text-xs" v-else>
										Selecione até {{ addon_group.max }} opções
									</div>
								</div>
								<div
									class="align-middle flex gap-2 text-right content-end justify-end"
								>
									<div
										v-if="addon_group.min > addon_group.qty"
										class="bg-red-100 border border-red-400 text-xs px-2 py-1 rounded-lg h-min"
									>
										Obrigatório
									</div>
									<div
										v-if="addon_group.min == 0"
										class="bg-gray-100 border border-gray-400 text-xs px-2 py-1 rounded-lg h-min"
									>
										Opcional
									</div>
									<div
										class="bg-teal-100 border border-teal-400 text-xs px-2 py-1 rounded-lg h-min"
									>
										{{ addon_group.qty }}/{{ addon_group.max }}
									</div>
								</div>
							</div>

							<div v-if="addon_group.items != null" class="my-2">
								<div
									class="grid grid-cols-2 bg-slate-100 px-2"
									v-for="item_addon in addon_group?.items"
									:key="item_addon.id"
								>
									<div>
										<div class="font-black text-left">
											{{ item_addon?.name }}
										</div>
										<div class="text-left">
											<span v-if="addon_group.is_agregate">
												<span v-if="addon_group.name.includes('Tamanho')">{{
													toCurrency(
														parseFloat(productModal.price) +
															parseFloat(item_addon.price)
													)
												}}</span>
												<span v-else>{{
													toCurrency(item_addon.price, true)
												}}</span>
											</span>
											<span v-else>{{
												toCurrency(item_addon?.price, true)
											}}</span>
										</div>
									</div>
									<div
										class="align-middle text-right content-end flex justify-end"
									>
										<div class="grid grid-cols-3 items-center text-center w-32">
											<div class="flex justify-center cursor-pointer">
												<Icon.Minus
													size="16"
													v-if="addon_group.qty > 0 && item_addon.qty > 0"
													@click="
														productModalRemoveAddon(addon_group, item_addon)
													"
												/>
											</div>
											<div class="flex justify-center">
												{{ item_addon.qty }}
											</div>
											<div class="flex justify-center cursor-pointer">
												<Icon.Plus
													size="16"
													v-if="addon_group.qty < addon_group.max"
													@click="productModalAddAddon(addon_group, item_addon)"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="grid grid-cols-2 bg-slate-200 p-2">
							<div>
								<div class="font-black text-left">Instruções</div>
							</div>
						</div>

						<div class="align-middle p-4">
							<textarea
								rows="3"
								class="block w-full text-md bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
								type="text"
								v-model="productModal.obs"
							/>
						</div>

						<div class="text-center mt-4">
							<button
								@click="productModalAdd"
								class="px-6 py-3 rounded-3xl bg-black text-white capitalize transition-all hover:bg-slate-600"
							>
								Adicionar {{ toCurrency(productModal.total_price) }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>


<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import Analytics from "./Analytics.vue";
</script>

<script>
export default {
	props: {
		store_id: { type: String },
		promotions: { type: String },
		products: { type: String },
		storeOpened: { type: Number },
		currentCart: { type: Object },
		currentAddress: { type: Object },
		orderValues: { type: Object },
		feePaidByStore: { type: Boolean, default: false },
	},
	data() {
		return {
			historyMode: true,
			categories: JSON.parse(this.products ?? "[]"),
			productsOnCart: [],
			productsFiltred: [],
			subtotal: 0,
			orderNumber: "",
			itemsCount: 0,
			currentStoreOpened: true,
			search: "",
			productModal: {},
			productModalOpened: false,
			current_addons: [],
			currentMenuItem: "",
		};
	},
	mounted() {
		console.log(this.promotions);
		window.addEventListener("scroll", this.onScroll);
		this.currentStoreOpened = this.storeOpened;
		this.productsOnCart = [];
		for (const [key, value] of Object.entries(
			this.currentCart.products ?? {}
		)) {
			this.productsOnCart.push(value);
		}
		this.itemsCount = this.productsOnCart.length;
		this.total = this.currentCart.total ?? 0;
		this.subtotal = this.currentCart.subtotal ?? 0;
		this.country = this.currentCart.country;
		this.deliveryMode = this.currentCart.deliveryMode ?? "delivery";

		this.onCartUpdated({
			products: this.productsOnCart,
			itemsCount: this.itemsCount,
			deliveryMode: this.deliveryMode,
			country: this.country,
			orderValues: this.orderValues,
		});
		this.emitter.on("cart.updated", this.onCartUpdated);
	},
	created() {},
	methods: {
		onCartUpdated(data) {
			this.subtotal = data.orderValues?.subtotal ?? 0;

			this.categories.forEach((category) => {
				category.menu_items.forEach((product) => {
					product.qtd = 0;
				});
			});

			data.products.forEach((product) => {
				this.categories.forEach((category) => {
					if (
						category.menu_items.filter((p) => p.id == product.id).length > 0
					) {
						category.menu_items.filter((p) => p.id == product.id)[0].qtd +=
							product.qty;
					}
				});
			}, this);

			this.itemsCount = data.itemsCount;

			this.productsFiltred = this.categories.filter(
				(c) =>
					!c.name.toLowerCase().trim().includes("uber") &&
					!c.name.toLowerCase().trim().includes("oculto")
			);

			console.log(this.productsFiltred);
		},
		add(product, qtd = 1, forceShowModal = false) {
			if (this.deliveryMode == "delivery" && this.currentAddress.name == "")
				this.emitter.emit("address.select");
			else {
				if (
					(product.menu_item_addon_groups?.length > 0 && qtd >= 0) ||
					forceShowModal
				) {
					this.showProductModal(product);
				} else {
					Analytics.methods.addToCart(
						product.id,
						product.name,
						product.price,
						qtd
					);
					document.Cart.add(product.id, qtd);
				}
			}
		},
		remove(product, qtd = 1) {
			Analytics.methods.removeFromCart(
				product.id,
				product.name,
				product.price,
				Math.abs(qtd)
			);
			document.Cart.add(product.id, qtd);
		},
		toggle(id) {
			document.getElementById(id).classList.toggle("hidden");
		},
		reset() {
			this.historyMode = true;
		},

		removeFilter() {
			this.productsFiltred = this.categories.filter(
				(c) =>
					!c.name.toLowerCase().trim().includes("uber") &&
					!c.name.toLowerCase().trim().includes("oculto")
			);
			this.search = "";
		},
		onSearchUpdate(search) {
			this.search = search;

			this.productsFiltred = [];

			this.categories.forEach((cat) => {
				let newCat = {
					id: cat.id,
					name: cat.name,
					order: cat.order,
					menu_items: [],
				};
				newCat.menu_items = cat.menu_items.filter((c) =>
					c.name.toLowerCase().trim().includes(search.toLowerCase().trim())
				);

				if (newCat.menu_items.length > 0) this.productsFiltred.push(newCat);
			});
		},

		showProductModal(product) {
			this.productModalOpened = true;
			this.productModal = product;
			this.productModal.total_price = this.productModal.price;
			this.productModal.obs = "";
			this.current_addons = [];

			product.menu_item_addon_groups?.forEach((addon_group) => {
				let addon = {};
				addon.id = addon_group.id;
				addon.name = addon_group.name;
				addon.is_agregate = addon_group.is_agregate == 1;
				addon.max = addon_group.max;
				addon.min = addon_group.min;
				addon.qty = 0;
				addon.selected = [];

				addon.items = [];

				addon_group.items.forEach((item) => {
					let addon_item = {};
					addon_item.id = item.id;
					addon_item.integration_id = item.integration_id;
					addon_item.name = item.name;
					addon_item.qty = 0;
					addon_item.price = addon.is_agregate
						? this.getAgregatePrice(
								this.productModal.price,
								item.price,
								addon_group.name.includes("tamanho")
						  )
						: item.price;
					addon_item.order = item.item_order;
					addon.order = item.group_order;

					addon.items.push(addon_item);
				});
				addon.items.sort((a) => a.order);

				this.current_addons.push(addon);
			});

			this.current_addons.sort((a) => a.order);
		},

		sortBy(array, p) {
			return array.slice(0).sort(function (a, b) {
				return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
			});
		},

		productModalClose() {
			this.productModalOpened = false;
			this.productModal = {};
		},
		productModalAdd() {
			let validationPassed = true;

			this.current_addons.forEach((addon) => {
				if (addon.min > addon.qty) {
					alert("Existe uma opção pendente para marcar");
					validationPassed = false;
				}
			});

			if (validationPassed) {
				let addonsData = [];

				this.current_addons.forEach((addon) => {
					let currentGroup = {
						id: addon.id,
						name: addon.name,
						items: [],
						price_total: 0,
					};

					addon.selected.forEach((selected_item) => {
						let currentItem = {
							id: selected_item.id,
							integration_id: selected_item.integration_id,
							name: selected_item.name,
							qty: selected_item.qty,
							price: selected_item.price,
							price_total:
								this.toNumber(selected_item.price) * selected_item.qty,
						};

						currentGroup.price_total += currentItem.price_total;
						currentGroup.items.push(currentItem);
					});

					if (currentGroup.items.length > 0) addonsData.push(currentGroup);
				});

				Analytics.methods.addToCart(
					this.productModal.id,
					this.productModal.name,
					this.productModal.total_price,
					1
				);

				document.Cart.add(
					this.productModal.id,
					1,
					addonsData,
					this.productModal.obs
				);
				this.productModalClose();
			}
		},
		productModalRemoveAddon(group, addon) {
			group.qty -= 1;
			addon.qty -= 1;

			if (addon.qty < 1) group.selected.pop();
			this.productModalUpdatePrice();
		},

		productModalAddAddon(group, addon) {
			group.qty += 1;
			addon.qty += 1;
			if (addon.qty == 1) group.selected.push(addon);

			this.productModalUpdatePrice();
		},

		productModalUpdatePrice() {
			this.productModal.total_price = this.toNumber(this.productModal.price);

			this.current_addons.forEach((addon) => {
				addon.selected.forEach((selected_item) => {
					this.productModal.total_price +=
						this.toNumber(selected_item.price) * selected_item.qty;
				});
			});
		},

		getAgregatePrice(price1, price2, enableMinus = false) {
			if (enableMinus) {
				price1 = parseFloat(price1);
				price2 = parseFloat(price2);
				return price2 - price1;
			}

			return parseFloat(price1) < parseFloat(price2)
				? parseFloat(price2) - parseFloat(price1)
				: 0;
		},

		updateSearchValue(event) {
			this.touched = true;
			this.updateValue(event.target.value);
		},
		updateValue: _debounce(function (value) {
			this.touched = false;
			this.onSearchUpdate(value);
		}, 600),

		onScroll() {
			const self = this;
			let all_categories = document.getElementById("all_categories");
			let all_categories2 = document.getElementById("all_categories2");
			let products = document.getElementById("products");
			let currentScrollCategoryId = "";

			let scrollcategory = document.querySelectorAll(".category-group");
			scrollcategory.forEach((category) => {
				let top = category.offsetTop - 130;
				let height = category.offsetHeight;
				let id = category.getAttribute("scroll-ref");
				if (
					window.scrollY >= top &&
					window.scrollY < top + height &&
					this.currentMenuItem != id
				) {
					this.currentScrollCategoryId = id;
				}
			});

			document
				.querySelectorAll("#all_categories .menu-item")
				.forEach((item) => {
					if (item.getAttribute("scroll-ref") != this.currentMenuItem)
						item.classList.remove("active");
				});
			if (this.currentMenuItem != this.currentScrollCategoryId) {
				this.currentMenuItem = this.currentScrollCategoryId;
				let currentMenuItemDom = document.querySelector(
					'#all_categories .menu-item[scroll-ref="' +
						this.currentMenuItem +
						'"]'
				);
				currentMenuItemDom?.classList.add("active");

				setTimeout(() => {
					let moveTo =
						document.querySelector(".menuscroll").scrollLeft +
						document.querySelector(".menu-item.active")?.getBoundingClientRect()
							.x;
					document
						.querySelector(".menuscroll")
						.scrollTo({ left: moveTo, behavior: "smooth" });
				}, 400);

				//document.querySelector('.menuscroll').scrollTo({left: document.querySelector('.menu-item.active').getBoundingClientRect().x, behavior: "smooth",})

				//document.querySelector('.menuscroll').scrollLeft = document.querySelector('.menu-item.active').getBoundingClientRect().x;
			}
		},

		toNumber(value) {
			return parseFloat(value, 2);
		},

		toCurrency(value, showPlus = false) {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}
			var formatter = new Intl.NumberFormat("pt-PT", {
				style: "currency",
				currency: "EUR",
			});

			return (showPlus && value > 0 ? "+" : "") + formatter.format(value);
		},
	},
};
</script>
