<template>
	<Loading :enabled="loading" />

	<div
		class="w-full h-full relative"
		v-show="!loading"
		id="mapX"
		ref="mapX"
	></div>
</template>

<script setup>
import _debounce from "lodash/debounce";
import Loading from "./Loading.vue";
</script>

<script>
export default {
	components: {},
	props: {
		user_type: String,
		order_id: { type: String, default: null },
		store_lat: { type: String, default: null },
		store_lng: { type: String, default: null },
		eater_lat: { type: String, default: null },
		eater_lng: { type: String, default: null },
	},
	data() {
		return {
			loading: true,
			order: null,
			directionsService: null,
			directionsRenderer: null,
			map: null,
			startPin: null,
			endPin: null,
			driverPin: null,
			interval: null,
			intervalTimeInSecs: 10,
			startLocation: {
				type: Object,
				default() {
					return {
						lat: 0,
						lng: 0,
					};
				},
			},

			endLocation: {
				type: Object,
				default() {
					return {
						lat: 0,
						lng: 0,
					};
				},
			},

			driverLocation: {
				type: Object,
				default() {
					return {
						lat: 0,
						lng: 0,
					};
				},
			},
		};
	},
	watch: {
		store_lat() {
			this.loadDirection();
		},
		store_lng() {
			this.loadDirection();
		},
		eater_lat() {
			this.loadDirection();
		},
		eater_lng() {
			this.loadDirection();
		},
	},
	mounted() {
		console.log("mounted");
		console.log("store_lat", this.store_lat);
		this.loading = true;

		if (this.order_id != null) {
			setTimeout(() => {
				this.loadData();
			}, 500);
		} else {
			this.loading = false;
			this.loadDirection();
		}
	},
	beforeUnmount() {
		clearInterval(this.interval);
	},
	methods: {
		loadData(update_zoom = true) {
			const self = this;

			console.log("loadData");

			if (!this.order_id) return;

			axios
				.get(`/api/v1/orders/${this.order_id}/status`)
				.then((response) => {
					self.order = response.data;
				})
				.catch((error) => {
					self.order = {};
					console.log(error);
				})
				.finally(async () => {
					this.loading = false;
					self.loadDirection(update_zoom);
				});
		},

		buildContent(icon) {
			const content = document.createElement("image");
			content.className = "custom-marker-elem";
			content.source = icon;
			content.src = icon;

			return content.documentElement;
		},

		format_float(value) {
			return parseFloat(value);
		},

		findCenter(markers) {
			const lats = markers.map((m) => m.lat);
			const lngs = markers.map((m) => m.lng);
			return {
				lat: (Math.min(...lats) + Math.max(...lats)) / 2,
				lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
			};
		},

		async loadDirection(update_zoom = true) {
			const self = this;
			if (
				update_zoom &&
				this.order.status != "DELIVERED" &&
				this.order.status != "CANCELED" &&
				this.order.driver_id != null
			) {
				clearInterval(this.interval);
				this.interval = setInterval(() => {
					this.loadData(false);
				}, 1000 * this.intervalTimeInSecs);
			}

			/*if (!this.directionsService)
				this.directionsService = new google.maps.DirectionsService();
			if (!this.directionsRenderer)
				this.directionsRenderer = new google.maps.DirectionsRenderer({
					preserveViewport: true,
					suppressMarkers: true,
				});*/

			if (this.order != null) {
				this.startLocation = {
					lat: this.format_float(this.order.store.lat),
					lng: this.format_float(this.order.store.lng),
				};
				this.driverLocation = {
					lat: this.format_float(this.order.driver_lat),
					lng: this.format_float(this.order.driver_lng),
				};

				this.endLocation = {
					lat: this.format_float(this.order.eater_address_lat),
					lng: this.format_float(this.order.eater_address_lng),
				};
			} else {
				this.startLocation = {
					lat: this.format_float(this.store_lat),
					lng: this.format_float(this.store_lng),
				};

				this.endLocation = {
					lat: this.format_float(this.eater_lat != "" ? this.eater_lat : 0),
					lng: this.format_float(this.eater_lng != "" ? this.eater_lng : 0),
				};
			}

			if (!this.map) {
				const { Map } = await google.maps.importLibrary("maps");
				const { AdvancedMarkerElement, Marker, PinElement } =
					await google.maps.importLibrary("marker");

				this.map = new Map(document.getElementById("mapX"), {
					mapId: "fd0cda7be9aa090e",
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					zoom: 14,
					disableDefaultUI: false,
					gestureHandling: "greedy",
				});

				//this.directionsRenderer.setMap(this.map);

				//StartLocation Pin
				this.startPin = new Marker({
					title: "Start",
					map: this.map,
					position: this.startLocation,
					icon: "/images/icons/pin_start.svg",
				});

				//EndLocation Pin
				this.endPin = new Marker({
					title: "End",
					map: this.map,
					position: this.endLocation,
					icon: "/images/icons/pin_end.svg",
				});

				//Driver Pin
				if (
					this.order != null &&
					this.order.status != "DELIVERED" &&
					this.order.status != "CANCELED" &&
					this.driverLocation.lat != null &&
					this.driverLocation.lng != null
				) {
					this.driverPin = new Marker({
						title: "Driver",
						map: this.map,
						position: this.driverLocation,
						icon: "https://images.eatz.pt/icon_map.png",
					});
				}
			}

			let bounds = new google.maps.LatLngBounds();
			bounds.extend(this.startLocation);
			console.log(bounds);
			if (this.endLocation.lat != 0 && this.endLocation.lng != 0)
				bounds.extend(this.endLocation);
			if (this.driverLocation.lat && this.driverLocation.lng)
				bounds.extend(this.driverLocation);

			if (update_zoom) {
				this.map.fitBounds(bounds);
				this.map.panToBounds(bounds);
			}

			this.changeMarkerPosition(
				this.startPin,
				this.startLocation.lat,
				this.startLocation.lng
			);
			this.changeMarkerPosition(
				this.endPin,
				this.endLocation.lat,
				this.endLocation.lng
			);
			if (
				this.order != null &&
				this.driverLocation.lat != null &&
				this.driverLocation.lng != null
			)
				this.changeMarkerPosition(
					this.driverPin,
					this.driverLocation.lat,
					this.driverLocation.lng
				);

			/*this.calculateAndDisplayRoute(
				this.directionsService,
				this.directionsRenderer
			);*/

			if (this.endLocation.lat == 0 && this.endLocation.lng == 0) {
				this.endPin.setVisible(false);
				this.map.setZoom(14);
			} else {
				this.endPin.setVisible(true);
			}
		},

		changeMarkerPosition(marker, lat, lng) {
			var latlng = new google.maps.LatLng(lat, lng);
			if (marker != null) marker.setPosition(latlng);
		},

		calculateAndDisplayRoute(directionsService, directionsRenderer) {
			console.log(
				"origin",
				this.order != null &&
					this.order.status == "ON_DELIVERY" &&
					this.driverLocation.lat &&
					this.driverLocation.lng
					? this.driverLocation
					: this.startLocation
			);
			console.log("endLocation", this.endLocation);

			directionsRenderer.setDirections({ routes: [] });

			if (this.endLocation.lat == 0 && this.endLocation.lng == 0) return;
			directionsService
				.route({
					origin:
						this.order != null &&
						this.order.status == "ON_DELIVERY" &&
						this.driverLocation?.lat &&
						this.driverLocation?.lng
							? this.driverLocation
							: this.startLocation,
					destination: this.endLocation,
					waypoints: this.waypnt,
					travelMode: window.google.maps.TravelMode.DRIVING,
				})
				.then((response) => {
					directionsRenderer.setDirections(response);
				})
				.catch((e) => console.log("Directions request failed due to " + e));
		},
	},
};
</script>
