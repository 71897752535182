<template>
	<div
		v-if="!newOrderModalOpened"
		class="flex items-center justify-between w-full mb-6"
	>
		<h1 class="text-xl font-bold">Pedidos</h1>

		<div class="flex my-auto gap-2" v-if="!newOrderModalOpened">
			<button @click="loadData()" class="rounded-xl bg-transparent">
				<Icon.RefreshCwIcon class="text-[#F8B604] w-8" />
			</button>
			<button
				v-if="
					user_type != 'fleet' &&
					store != null &&
					store.id != '' &&
					store.id != '*'
				"
				@click="createNewOrder()"
				class="px-3 rounded-xl bg-[#2761F4] text-white md:block hidden"
			>
				Criar&nbsp;Pedido
			</button>
			<button
				v-if="store != null && store.id != '' && store.id != '*'"
				@click="createNewOrder()"
				class="px-0 rounded-xl text-[#2761F4] md:hidden block"
			>
				<Icon.PlusIcon class="text-[#F8B604] w-8" />
			</button>
			<label class="block font-bold mt-2">Dia</label>
			<input
				type="date"
				v-model="currentDate"
				@input="updateDate($event.target.value)"
				class="block w-full bg-transparent rounded-lg border border-default-200 focus:ring-transparent focus:border-default-200 text-xs"
			/>
		</div>
	</div>

	<Loading :enabled="loading" />

	<div
		v-if="!loading && !newOrderModalOpened"
		class="lg:flex block flex-row gap-3 grow min-h-full"
		style="min-height: calc(100vh - 170px)"
	>
		<div
			class="relative mx-auto rounded-xl md:bg-white bg-none md:w-[400px] w-12/12 md:p-3 p-0 lg:h-full h-auto"
		>
			<div class="flex bg-gray-100 hover:bg-gray-200 rounded-lg transition p-1">
				<nav
					class="flex gap-x-1 w-full"
					aria-label="Tabs"
					role="tablist"
					aria-orientation="horizontal"
				>
					<button
						type="button"
						@click="resetCurrentOrder"
						class="hs-tab-active:bg-[#f8b604] text-center w-6/12 hs-tab-active:text-gray-700 py-3 px-4 items-center gap-x-2 bg-transparent md:text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 font-sm rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none active"
						id="segment-item-1"
						aria-selected="true"
						data-hs-tab="#segment-1"
						aria-controls="segment-1"
						role="tab"
					>
						Ativos ({{ orders_active.length }})
					</button>
					<button
						type="button"
						@click="resetCurrentOrder"
						class="hs-tab-active:bg-[#f8b604] text-center w-6/12 hs-tab-active:text-gray-700 py-3 px-4 items-center gap-x-2 bg-transparent md:text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 font-sm rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none"
						id="segment-item-2"
						aria-selected="false"
						data-hs-tab="#segment-2"
						aria-controls="segment-2"
						role="tab"
					>
						Finalizados ({{ orders_done.length }})
					</button>
				</nav>
			</div>

			<div class="">
				<div
					class="p-2"
					id="segment-1"
					role="tabpanel"
					aria-labelledby="segment-item-1"
				>
					<div class="flex flex-col gap-4">
						<div
							class="w-full text-center my-6"
							v-if="
								orders_new.length == 0 &&
								orders_ready.length == 0 &&
								orders_delivering.length == 0
							"
						>
							Sem pedidos
						</div>

						<div class="flex flex-wrap gap-4" v-if="orders_new.length > 0">
							<p>Novos ({{ orders_new.length }})</p>
							<div
								v-for="current_order in orders_new"
								:key="'new' + current_order.id"
								class="shadow-soft-xl w-full"
							>
								<card
									v-if="current_order.type == 'delivery'"
									:data="current_order"
									status="NEW"
									:selected="current_order.id == order?.id"
									color="#1654f4"
									textcolor="white"
									class="cursor-pointer w-full"
									@click="showOrder(current_order)"
									:user_type="user_type"
								/>
								<card
									v-else
									:data="current_order"
									status="NEW"
									:selected="current_order.id == order?.id"
									color="#7ba0ff"
									textcolor="white"
									class="cursor-pointer w-full"
									@click="showOrder(current_order)"
									:user_type="user_type"
								/>
							</div>
						</div>

						<div class="flex flex-wrap gap-4" v-if="orders_ready.length > 0">
							<p>Prontos ({{ orders_ready.length }})</p>
							<div
								v-for="current_order in orders_ready"
								:key="'ready' + current_order.id"
								class="shadow-soft-xl w-full"
							>
								<card
									v-if="current_order.type == 'delivery'"
									:data="current_order"
									status="READY"
									:selected="current_order.id == order?.id"
									color="#466D1D"
									textcolor="white"
									class="cursor-pointer w-full"
									@click="showOrder(current_order)"
									:user_type="user_type"
								/>
								<card
									v-else
									:data="current_order"
									status="READY"
									:selected="current_order.id == order?.id"
									color="#466D1D"
									textcolor="white"
									class="cursor-pointer w-full"
									@click="showOrder(current_order)"
									:user_type="user_type"
								/>
							</div>
						</div>

						<div
							class="flex flex-wrap gap-4"
							v-if="orders_delivering.length > 0"
						>
							<p>Em Entrega ({{ orders_delivering.length }})</p>
							<div
								v-for="current_order in orders_delivering"
								:key="'delivering' + current_order.id"
								class="shadow-soft-xl w-full"
							>
								<card
									:data="current_order"
									status="ON_DELIVERY"
									:selected="current_order.id == order?.id"
									color="#ffcc00"
									class="cursor-pointer w-full"
									@click="showOrder(current_order)"
									:user_type="user_type"
								/>
							</div>
						</div>
					</div>
				</div>

				<div
					class="p-2 hidden"
					id="segment-2"
					role="tabpanel"
					aria-labelledby="segment-item-2"
				>
					<div class="flex flex-wrap gap-4">
						<div
							v-for="current_order in orders_done"
							:key="'done' + current_order.id"
							class="shadow-soft-xl w-full"
						>
							<card
								:data="current_order"
								status="DELIVERED"
								:selected="current_order.id == order?.id"
								color="white"
								class="cursor-pointer w-full"
								@click="showOrder(current_order)"
								:user_type="user_type"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="relative mx-auto rounded-xl md:bg-white bg-none grow md:p-3 p-0 lg:mt-0 mt-4"
		>
			<div v-if="order?.id != null">
				<div class="w-full bg-red flex flex-row justify-items-stretch">
					<h2 class="text-lg text-black font-black pt-4 grow">
						Detalhes do Pedido
					</h2>
					<div class="my-2 justify-self-end flex flex-row gap-2">
						<button @click="copyShare(order)" class="rounded-xl bg-transparent">
							<Icon.Share class="text-[#F8B604] w-8" />
						</button>
						<integrator-icon :integrator="order.integrator" class="w-10" />
					</div>
				</div>

				<div
					v-if="order?.type == 'takeaway'"
					class="font-sans leading-normal text-center text-[12px] py-1 bg-[#25253D] rounded-t-2xl text-white uppercase"
				>
					{{ order?.type }}
				</div>
				<div
					v-else
					class="font-sans leading-normal text-center text-[12px] py-1 bg-[#1654f4] rounded-t-2xl text-white uppercase"
				>
					{{ order?.type }}
				</div>
				<div class="grow rounded-b-xl border border-default-400 p-4">
					<div class="md:flex gap-4">
						<div class="md:w-6/12">
							<div class="font-bold text-lg text-[#A098AE]">
								<span class="uppercase text-black">#{{ order.code }}</span>
								{{ order.eater_name }}
							</div>
							<div class="text-black">
								<span class="text-[#261e33]">Status:</span>
								{{ orderStatus(order.status) }}
							</div>
							<div class="text-black">
								<span class="text-[#A098AE]">Realizado:</span>
								{{ moment(order.created_at).format("HH:mm") }}
							</div>
							<div class="text-black" v-if="order.ready_for_pick_up_time">
								<span class="text-[#A098AE]">Pronto:</span>
								{{ moment(order.ready_for_pick_up_time).format("HH:mm") }}
							</div>
							<div class="text-black" v-if="order.delivered_time">
								<span class="text-[#A098AE]">Entregue:</span>
								{{ moment(order.delivered_time).format("HH:mm") }}
							</div>
						</div>
						<div class="md:w-3/12">
							<div class="text-black">
								<span class="text-[#A098AE]">Telemóvel:</span>
								<a
									href="#"
									@click="callToNumber(order.eater_phone)"
									class="underline"
									>{{ order.eater_phone }}</a
								>
							</div>
							<div class="text-black">
								<span class="text-[#A098AE]">NIF:</span>
								{{ order.eater_nif ?? "-" }}
							</div>
						</div>
						<div class="md:w-3/12">
							<div v-if="order.type == 'delivery' && order.driver_name">
								<div class="text-[#A098AE]">Estafeta</div>
								<div class="text-black">{{ order.driver_name }}</div>
								<div class="text-black">
									<a
										href="#"
										@click="callToNumber(order.driver_phone)"
										class="underline"
										>{{ order.driver_phone }}</a
									>
								</div>
								<integrator-icon
									:integrator="order.delivery_platform"
									class="w-5"
								/>
							</div>
							<div
								v-if="
									order.type == 'takeaway' &&
									order.estimated_ready_for_pickup_at
								"
							>
								<div class="text-[#A098AE]">Previsão de Entrega</div>
								<div class="text-black">
									{{
										moment(order.estimated_ready_for_pickup_at).format("HH:mm")
									}}
								</div>
							</div>
						</div>
					</div>
					<hr
						class="h-px my-8 bg-gray-400 border-0"
						v-if="order.type == 'delivery'"
					/>
					<div class="md:flex gap-4" v-if="order.type == 'delivery'">
						<div class="md:w-6/12">
							<div class="text-[#A098AE]">Morada de Entrega</div>
							<div class="text-black flex" v-if="order.address">
								<div><Icon.MapPin class="text-[#F8B604] w-8" /></div>
								<div>
									{{ order.address.address }},
									{{ order.address.address_number }}
									{{ order.address.appartment_number }} -
									{{ order.address.zipcode }} <br />{{
										order.address.area_level_2
									}}
									- {{ order.address.country }} <br />{{ order.address.obs }}
								</div>
							</div>
							<div class="text-black flex" v-else>
								<div><Icon.MapPin class="text-[#F8B604] w-8" /></div>
								<div>-</div>
							</div>
						</div>

						<div class="md:w-3/12">
							<div class="text-[#A098AE]">Tempo Estimado</div>
							<div>{{ order.address?.time ?? "-" }} Min</div>

							<div class="text-[#A098AE] mt-2">Distância</div>
							<div>{{ order.address?.distance ?? "-" }} Km</div>
						</div>

						<div class="md:w-3/12">
							<div class="text-[#A098AE]">Previsão de Entrega</div>
							<div v-if="order.estimated_delivery_at">
								{{ moment(order.estimated_delivery_at).format("HH:mm") }}
							</div>
							<div v-else>-</div>

							<div
								v-if="order.status == 'DELIVERED'"
								class="text-[#A098AE] mt-2"
							>
								Entregue
							</div>
							<div v-if="order.status == 'DELIVERED'">
								{{
									order.delivered_time
										? moment(order.delivered_time).format("HH:mm")
										: "-"
								}}
							</div>
						</div>
					</div>
					<hr
						v-if="order.items.length > 0"
						class="h-px my-8 bg-gray-400 border-0"
					/>

					<div v-if="order.items.length > 0" class="flex flex-col gap-4">
						<table
							className="w-full border-collapse border border-gray-300 md:text-sm text-xs"
						>
							<thead>
								<tr className="bg-gray-200">
									<th className="border border-gray-300 p-2 text-left w-full">
										Produto
									</th>
									<th className="border border-gray-300 p-2 text-center w-32">
										Preço
									</th>
									<th className="border border-gray-300 p-2 text-center w-24">
										Qtd
									</th>
									<th className="border border-gray-300 p-2 text-center w-32">
										Total
									</th>
									<th
										v-if="order.markup"
										className="border border-gray-300 p-2 text-center w-32"
									>
										Líquido <small>(-{{ order.markup }})</small>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									className="text-center"
									v-for="item in order.items"
									:key="item.id"
								>
									<td className="border border-gray-300 p-2 text-left">
										<div>
											<b>{{ item.name }}</b>
										</div>
										<div
											v-if="
												item.item_addons != null && item.item_addons.length > 0
											"
											class="ml-2 text-sx"
										>
											<p v-for="addon in item.item_addons" :key="addon.id">
												{{ addon.quantity }} x {{ addon.name }} =
												{{ toCurrency(addon.price * addon.quantity) }}
											</p>
										</div>
										<div
											v-if="item.obs != null && item.obs != ''"
											class="ml-2 text-sx"
										>
											<strong>Obs.:</strong> {{ item.obs }}
										</div>
									</td>
									<td className="border border-gray-300 p-2">
										<span
											class="line-through"
											v-if="
												item.name.toLowerCase() == 'taxa de serviço' &&
												order.service_fee != item.price
											"
											>{{ toCurrency(order.service_fee, "") }}&nbsp;<span
												class="text-[#F8B604]"
												>€</span
											></span
										>
										<span
											class="line-through"
											v-else-if="
												item.name.toLowerCase() == 'taxa de entrega' &&
												order.delivery_price != item.price
											"
											>{{ toCurrency(order.delivery_price, "") }}&nbsp;<span
												class="text-[#F8B604]"
												>€</span
											></span
										>
										<span v-else>
											{{ toCurrency(item.price, "") }}&nbsp;<span
												class="text-[#F8B604]"
												>€</span
											></span
										>
									</td>
									<td className="border border-gray-300 p-2">
										<span
											v-if="
												item.name.toLowerCase() != 'taxa de entrega' &&
												item.name.toLowerCase() != 'taxa de serviço'
											"
										>
											{{ item.quantity }}
										</span>
									</td>
									<td className="border border-gray-300 p-2">
										{{ toCurrency(item.price * item.quantity, "") }}&nbsp;<span
											class="text-[#F8B604]"
											>€</span
										>
									</td>
									<td
										v-if="
											order.markup &&
											item.name.toLowerCase() != 'taxa de entrega' &&
											item.name.toLowerCase() != 'taxa de serviço' &&
											item.name.toLowerCase() != 'taxa regulatória sobre saco'
										"
										className="border border-gray-300 p-2"
									>
										{{
											toCurrency(
												item.price *
													item.quantity *
													(order.markup.includes("%")
														? (100 - order.markup.replace("%", "")) / 100
														: 1),
												""
											)
										}}&nbsp;<span class="text-[#F8B604]">€</span>
									</td>
								</tr>
								<tr
									className="text-center"
									v-if="
										order.integrator == 'eatz' &&
										!order.items.find((item) => item.name == 'Taxa de Serviço')
									"
								>
									<td className="border border-gray-300 p-2 text-left">
										<b>Taxa de Serviço</b>
									</td>
									<td className="border border-gray-300 p-2 line-through">
										{{ toCurrency(order.service_fee, "") }}&nbsp;<span
											class="text-[#F8B604]"
											>€</span
										>
									</td>
									<td className="border border-gray-300 p-2"></td>
									<td className="border border-gray-300 p-2">
										{{ toCurrency(0, "") }}&nbsp;<span class="text-[#F8B604]"
											>€</span
										>
									</td>
								</tr>
								<tr className="" v-if="order.price_with_discount > 0">
									<td
										colspan="3"
										className="border border-gray-300 p-2 font-bold text-end"
									>
										Total
									</td>
									<td className="border border-gray-300 p-2">
										{{ toCurrency(order.price_with_discount, "") }}&nbsp;<span
											class="text-[#F8B604]"
											>€</span
										>
									</td>
								</tr>
								<tr className="bg-gray-200">
									<td
										colspan="3"
										className="border border-gray-300 p-2 font-bold text-end"
									>
										Valor líquido<small v-if="order.integrator == 'eatz'">
											(após taxas Stripe)</small
										>
									</td>
									<td className="border border-gray-300 p-2">
										{{ toCurrency(order.store_amount, "") }}&nbsp;<span
											class="text-[#F8B604]"
											>€</span
										>
									</td>
								</tr>
							</tbody>
						</table>

						<div v-if="order.obs != null && order.obs != ''">
							<div class="font-black text-left">Observação</div>
							<div>{{ order.obs }}</div>
						</div>
					</div>

					<hr
						v-if="order.type == 'delivery'"
						class="h-px my-8 bg-gray-400 border-0"
					/>

					<div class="h-96" v-if="order.type == 'delivery'">
						<MapComponent :order_id="order.id" :user_type="user_type" />
					</div>
				</div>

				<div id="receipt_print" class="hidden print:block mb-4">
					<div class="grid grid-cols-1 gap-2">
						<div class="relative">
							<h2 class="flex-auto text-black font-black">
								Pedido #{{ order.code }}
							</h2>
							<integrator-icon
								:integrator="order.integrator"
								class="w-10 flex-none absolute top-0 right-0"
							/>
						</div>

						<div>
							<div class="capitalize">
								<strong>Tipo:</strong> {{ order.type }}
							</div>
							<div>
								<strong>Realizado:</strong>
								{{ moment(order.created_at).format("DD/MM/YYYY HH:mm") }}
							</div>
							<div v-if="order.estimated_delivery_at">
								<strong>Previsão de Entrega:</strong>
								{{
									moment(order.estimated_delivery_at).format("DD/MM/YYYY HH:mm")
								}}
							</div>
							<div v-if="order.delivered_time">
								<strong>Entregue:</strong>
								{{ moment(order.delivered_time).format("DD/MM/YYYY HH:mm") }}
							</div>
							<div v-if="order.price_with_discount">
								<strong>Valor do Pedido:</strong>
								{{ toCurrency(order.price_with_discount) }}
							</div>
						</div>

						<div>
							<div class="bg-slate-200 font-black text-left my-2">Cliente</div>
							<div>
								<div><strong>Nome:</strong> {{ order.eater_name }}</div>
								<div><strong>Telemóvel:</strong> {{ order.eater_phone }}</div>
								<div v-if="order.nif != ''">
									<strong>NIF:</strong> {{ order.eater_nif }}
								</div>
								<div v-if="order.eater_address">
									<strong>Morada:</strong> {{ order.eater_address }},
									{{ order.eater_address_number }}
									{{ order.eater_appartment_number }} -
									{{ order.eater_zipcode }}
								</div>
							</div>
						</div>

						<div class="flex flex-col gap-2">
							<table className="w-full border-collapse text-sm">
								<thead>
									<tr className="bg-slate-200 my-2">
										<th className="text-left w-full">Produtos</th>
										<th className="text-center w-32">Total</th>
									</tr>
								</thead>
								<tbody>
									<tr
										className="text-center border-b border-dotted border-black"
										v-for="item in order.items"
										:key="item.id"
									>
										<td className="text-left">
											<b
												><span
													v-if="
														item.name.toLowerCase() != 'taxa de serviço' &&
														item.name.toLowerCase() != 'taxa de entrega' &&
														item.name.toLowerCase() !=
															'taxa regulatória sobre saco'
													"
													>{{ item.quantity }} x </span
												>{{ item.name }}</b
											>
											<div
												v-if="
													item.item_addons != null &&
													item.item_addons.length > 0
												"
												class="ml-2"
											>
												<p v-for="addon in item.item_addons" :key="addon.id">
													{{ addon.quantity }} x {{ addon.name }} =
													{{ toCurrency(addon.price * addon.quantity) }}
												</p>
											</div>
											<div
												v-if="item.obs != null && item.obs != ''"
												class="ml-2"
											>
												<strong>Obs.:</strong> {{ item.obs }}
											</div>
										</td>
										<td className="text-right">
											{{ toCurrency(item.price * item.quantity, "") }}&nbsp;€
										</td>
									</tr>
									<tr
										className="text-center"
										v-if="order.price_with_discount > 0"
									>
										<td className="font-bold text-end pr-3">Total</td>
										<td className="text-right">
											{{ toCurrency(order.price_with_discount, "") }}&nbsp;€
										</td>
									</tr>
								</tbody>
							</table>

							<div v-if="order.obs != null && order.obs != ''">
								<div class="bg-slate-200 font-black text-left">Observação</div>
								<div>{{ order.obs }}</div>
							</div>

							<div
								v-if="order.delivery_obs != null && order.delivery_obs != ''"
							>
								<div class="bg-slate-200 font-black text-left">
									Observação de entrega
								</div>
								<div>{{ order.delivery_obs }}</div>
							</div>
						</div>
					</div>
					<div class="mt-6 text-center w-full align-middle">
						Powered by Eatz
					</div>
				</div>

				<div
					class="flex flex-wrap w-full justify-end print:hidden gap-2 p-2"
					v-if="user_type != 'fleet'"
				>
					<button
						v-if="
							order.driver_id != null &&
							order.type == 'delivery' &&
							order.status != 'DELIVERED' &&
							order.status != 'CANCELED'
						"
						@click="removeDriver(order)"
						class="px-6 py-3 rounded-xl bg-[red] text-white capitalize transition-all"
					>
						Remover Estafeta
					</button>
					<button
						v-print="printOrder"
						class="px-6 py-3 rounded-xl bg-[#F8B604] text-white capitalize transition-all"
					>
						Imprimir
					</button>

					<button
						v-if="order.status == 'NEW' || order.status == 'ACCEPTED'"
						@click="makeReady(order)"
						class="px-6 py-3 rounded-xl bg-[#2761F4] text-white capitalize transition-all"
					>
						Pronto
					</button>

					<button
						v-if="
							order.type == 'delivery' &&
							order.workflow == 'c' &&
							(order.status == 'NEW' || order.status == 'READY') &&
							order.driver_requested != 1
						"
						@click="requestDriver(order)"
						class="px-6 py-3 rounded-xl bg-[#2761F4] text-white capitalize transition-all"
					>
						Solicitar Estafeta
					</button>

					<button
						v-if="
							order.type == 'takeaway' &&
							(order.status == 'NEW' || order.status == 'READY')
						"
						@click="makeDelivered(order)"
						class="px-6 py-3 rounded-xl bg-primary text-white capitalize transition-all hover:bg-primary"
					>
						Entregue
					</button>

					<button
						v-if="
							order.status == 'NEW' ||
							order.status == 'READY' ||
							order.status == 'ACCEPTED'
						"
						@click="makeCanceled(order)"
						class="px-6 py-3 rounded-xl bg-[red] text-white capitalize transition-all"
					>
						Cancelar
					</button>
				</div>
			</div>
		</div>
	</div>

	<div
		v-if="!loading && newOrderModalOpened"
		id="form_new_order"
		class="lg:flex block flex-row gap-3 grow min-h-full rounded-xl my-2 p-2 bg-white"
		style="min-height: calc(100vh - 170px)"
	>
		<!--content-->
		<div class="w-full min-h-full relative flex flex-col grow">
			<!--body-->
			<div class="absolute z-10 w-full top-0 bg-red">
				<h2 class="text-center text-lg text-black font-black pt-4">
					Criar novo Pedido
				</h2>
			</div>

			<div class="grid md:grid-cols-2 grid-cols-1 gap-4 mt-12 grow p-4">
				<div class="">
					<div class="relative w-full gap-2 flex flex-col">
						<div class="flex-auto">
							<label class="block font-medium text-default-900 mb-2"
								>Morada para levantamento</label
							>

							<div class="flex gap-2 my-2">
								<div class="w-10 justify-center items-center my-auto">
									<Icon.MapPin class="m-auto" />
								</div>

								<div class="text-start grow">
									<b>{{ store.name }}</b
									><br />
									{{ store.address }} {{ store.address_number }}<br />
									{{ store.city }} - {{ store.state }}<br />
									Código Postal: {{ store.zipcode }}
								</div>
							</div>
						</div>

						<hr class="h-px my-2 bg-gray-400 border-0" />

						<div class="flex-auto">
							<label class="block font-medium text-default-900 mb-2"
								>Nome Cliente*</label
							>
							<input
								maxlength="25"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
								type="text"
								v-model="eater_name"
							/>
						</div>

						<div class="flex-auto">
							<label class="block font-medium text-default-900 mb-2"
								>Telemóvel*</label
							>

							<vue-tel-input
								v-model="eater_phone"
								@validate="eater_phone_updated"
							/>
						</div>

						<div class="flex-auto">
							<label class="block font-medium text-default-900 mb-2">NIF</label>
							<input
								maxlength="9"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
								type="text"
								v-model="eater_nif"
							/>
						</div>

						<div>
							<label class="block font-medium text-default-900 mb-2"
								>Morada*</label
							>
							<div class="relative w-full" v-if="searchAddressEnabled">
								<div
									class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
								>
									<Icon.MapPin :size="15" class="w-4 h-4 text-gray-500" />
								</div>

								<input
									type="text"
									id="simple-search"
									class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
									placeholder="Pesquisar"
									ref="streetRef"
									role="presentation"
									autocomplete="off"
									@input="updateInternalValue"
									v-model="searchText"
								/>
							</div>

							<div class="flex gap-2 my-2" v-if="!searchAddressEnabled">
								<div class="w-10 justify-center items-center my-auto">
									<Icon.MapPin class="m-auto" />
								</div>

								<div class="text-start grow">
									{{ address }} {{ address_number }}<br />
									{{ address_area_level_3 }} - {{ address_area_level_2 }}<br />
									Código Postal: {{ address_zipcode }}
								</div>

								<button
									@click="resetCurrentAddress()"
									class="px-6 py-3 rounded-lg bg-primary text-white capitalize transition-all hover:bg-primary"
								>
									Alterar
								</button>
							</div>

							<Loading :enabled="newOrderLoading" />

							<div v-if="!newOrderLoading">
								<div
									v-if="
										!confirmMode &&
										sugestionsList.length > 0 &&
										searchText != ''
									"
								>
									<div
										v-for="sugestion in sugestionsList"
										:key="sugestion.place_id"
										class="flex gap-1 hover:bg-gray-200 rounded-md shadow my-2 cursor-pointer p-2"
										@click="onAddressSelected(sugestion)"
									>
										<div class="w-10 justify-center items-center my-auto">
											<Icon.MapPin class="m-auto" />
										</div>

										<div class="text-start my-auto">
											<div>{{ sugestion.description }}</div>
										</div>
									</div>
								</div>

								<div
									v-if="
										sugestionsList.length == 0 &&
										searchText != undefined &&
										searchText != '' &&
										searchText.length >= 5 &&
										!confirmMode
									"
								>
									<div class="mt-6">
										Não encontramos nenhuma morada, tente novamente.
									</div>
								</div>
							</div>
						</div>

						<div class="">
							<label class="block font-medium text-default-900 mb-2"
								>Número Andar/apartamento</label
							>
							<input
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
								type="text"
								v-model="appartment_number"
							/>
						</div>

						<div class="">
							<label class="block font-medium text-default-900 mb-2"
								>Observações para entrega</label
							>
							<textarea
								rows="2"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
								type="text"
								v-model="confirmObs"
							/>
						</div>

						<div class="">
							<label class="block font-medium text-default-900 mb-2"
								>Tempo de preparo (em minutos)</label
							>
							<input
								maxlength="2"
								class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
								type="number"
								v-model="preparation_time"
							/>
						</div>

						<div v-if="confirmMode && address_valid">
							<b>Distância:</b> {{ quoteDistance }} Km
							<br />
							<b>Valor:</b> {{ toCurrency(quotePrice) }}
						</div>
					</div>

					<div v-if="address_number_error != ''" class="my-2 text-red-700">
						{{ address_number_error }}
					</div>
					<div class="flex justify-end mt-2 gap-2">
						<button
							@click="closeNewOrder()"
							class="px-6 py-3 rounded-lg bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Cancelar
						</button>
						<button
							v-if="
								confirmMode &&
								eater_name?.length >= 3 &&
								eater_phone_valid &&
								address_valid
							"
							@click="createNewOrderConfirmed()"
							class="px-6 py-3 rounded-lg bg-primary text-white capitalize transition-all hover:bg-primary"
						>
							Confirmar
						</button>
					</div>
				</div>
				<div
					v-if="address_lat != null && address_lng != null"
					class="w-full md:h-full h-96"
				>
					<MapComponent
						class="h-full"
						:store_lat="store?.lat"
						:store_lng="store?.lng"
						:eater_lat="address_lat"
						:eater_lng="address_lng"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.vue-tel-input {
	border-radius: 0.5rem !important;
	border-color: hsl(214.29999999999995 31.8% 91.4% / 1) !important;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-left: 0.1rem;
	padding-right: 0.1rem;
}
</style>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import moment from "moment";
import OrderCard from "./OrdersManagerCard.vue";
import Loading from "./Loading.vue";
import Pusher from "pusher-js";
import { replace } from "lodash";
</script>

<script>
Pusher.logToConsole = false;

export default {
	components: {
		card: OrderCard,
	},
	props: {
		user_type: String,
		store: Object,
	},
	data() {
		return {
			loading: true,
			newOrderLoading: false,
			currentDate: moment().format("YYYY-MM-DD"),
			orders: [],
			orders_active: [],
			orders_new: [],
			orders_ready: [],
			orders_accepted: [],
			orders_delivering: [],
			orders_done: [],
			order: {},
			quoteId: "",
			quotePrice: 0,
			quoteDistance: 0,
			quoteExpiration: null,
			address_valid: false,
			address_zipcode: "",
			address_lat: "",
			address_lng: "",
			address_area_level_1: "",
			address_area_level_2: "",
			address_area_level_3: "",
			address_country: "",
			preparation_time: 20,
			newOrder: {},
			newOrderTemp: {},
			sugestionsList: [],
			confirmMode: false,
			confirmPlace: null,
			confirmPlace2: null,
			confirmPlaceId: 0,
			confirmObs: "",
			confirmObsDefault: "",
			searchAddressEnabled: true,
			searchText: "",
			eater_name: "",
			eater_phone: "",
			eater_phone_valid: false,
			eater_nif: "",
			address_number: "",
			appartment_number: "",
			address_number_error: "",
			newOrderModalOpened: false,
			printOrder: {
				id: "receipt_print",
				popTitle: "",
				beforeOpenCallback(vue) {
					//vue.printLoading = true
					//console.log('打开之前')
				},
				openCallback(vue) {
					//vue.printLoading = false
					//console.log('执行了打印')
				},
				closeCallback(vue) {
					//console.log('关闭了打印工具')
				},
			},
		};
	},
	mounted() {
		this.pusher = new Pusher("5ffe2ab47cbc08c20ae9", { cluster: "eu" });
		this.channelOrders = this.pusher.subscribe("orders");
		this.channelOrders.bind("orders", (data) => {
			console.log(data);

			if (data?.store_id == this.store.id) this.loadData();
		});

		this.loadData();
	},
	methods: {
		loadData() {
			const self = this;
			this.loading = true;

			if (this.order?.id != null) {
				this.tmpOrderId = this.order.id;
			}

			this.order = {};

			axios
				.post(this.user_type + "/orders/by_date", {
					store: this.store?.id ?? "*",
					date: this.currentDate,
				})
				.then((response) => {
					self.orders = response.data.orders;

					self.orders_active = self.orders.filter((order) =>
						["NEW", "ACCEPTED", "READY", "ON_DELIVERY"].includes(order.status)
					);
					self.orders_new = self.orders.filter(
						(order) =>
							order.status == "NEW" ||
							(order.status == "ACCEPTED" &&
								order.ready_for_pick_up_time == null)
					);
					self.orders_ready = self.orders.filter(
						(order) =>
							order.status == "READY" ||
							(order.status == "ACCEPTED" &&
								order.ready_for_pick_up_time != null)
					);
					self.orders_accepted = self.orders.filter(
						(order) => order.status == "ACCEPTED"
					);
					self.orders_delivering = self.orders.filter(
						(order) => order.status == "ON_DELIVERY"
					);
					self.orders_done = self.orders.filter(
						(order) => order.status == "DELIVERED"
					);
				})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					self.loading = false;
					if (self.tmpOrderId) {
						self.order = self.orders_active.find(
							(order) => order.id == self.tmpOrderId
						);
					}
				});
		},

		eater_phone_updated(phoneObject) {
			this.eater_phone_valid = phoneObject.valid;
			console.log("eater_phone_updated", phoneObject);
		},

		checkIsValidNumber(r) {
			if (Number.isNaN(Number.parseInt(r))) {
				return "";
			}
			return parseFloat(r);
		},

		format_float(value) {
			return parseFloat(value);
		},

		resetCurrentOrder(event) {
			this.order = {};
		},

		makeReady(order) {
			const self = this;
			this.loading = true;

			axios
				.post(this.user_type + "/orders/set_ready", { orderid: order.id })
				.then((response) => {})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					self.loading = false;
					self.newOrderModalOpened = false;
					self.loadData();
				});
		},

		makeCanceled(order) {
			const self = this;
			if (
				!confirm(
					"Tem a certeza de que deseja criar o pedido?\nSerá possível cancelar posteriormente, mas poderão ser aplicados custos."
				)
			)
				return;

			this.loading = true;

			axios
				.post(this.user_type + "/orders/set_canceled", { orderid: order.id })
				.then((response) => {})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					self.loading = false;
					self.newOrderModalOpened = false;
					self.loadData();
				});
		},

		requestDriver(order) {
			const self = this;
			this.loading = true;

			axios
				.post(this.user_type + "/orders/request_driver", { orderid: order.id })
				.then((response) => {})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					self.loading = false;
					self.newOrderModalOpened = false;
					self.loadData();
				});
		},

		removeDriver(order) {
			const self = this;
			if (
				!confirm(
					"Tem a certeza de que deseja remover o estafeta?\nSe for pela Glovo irá cancelar a entrega"
				)
			)
				return;

			this.loading = true;

			axios
				.post(this.user_type + "/orders/remove_driver", { orderid: order.id })
				.then((response) => {})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					self.loading = false;
					self.newOrderModalOpened = false;
					self.loadData();
				});
		},

		makeDelivered(order) {
			const self = this;
			this.loading = true;

			axios
				.post(this.user_type + "/orders/set_delivered", { orderid: order.id })
				.then((response) => {})
				.catch((error) => {
					self.orders = [];
					console.log(error);
				})
				.finally(() => {
					self.loading = false;
					self.newOrderModalOpened = false;
					self.loadData();
				});
		},

		sendToZonesoft(order) {
			const self = this;
			this.loading = true;

			axios
				.post(this.user_type + "/orders/send_to_zonesoft", {
					orderid: order.id,
				})
				.then((response) => {})
				.catch((error) => {
					alert(error);
				})
				.finally(() => {
					self.loading = false;
					alert("send ok");
				});
		},

		createNewOrder() {
			this.newOrder = {};
			this.newOrderTemp = {};
			this.sugestionsList = [];
			this.confirmMode = false;
			this.address = "";
			this.address_number = "";
			this.eater_name = "";
			this.eater_phone = "";
			this.eater_phone_valid = false;
			this.eater_nif = "";
			this.newOrderModalOpened = true;
			this.quoteDistance = 0;
			this.quotePrice = 0;
			this.preparation_time = 20;
		},

		showOrder(order) {
			const self = this;
			this.order = {};
			this.order.items = [];

			setTimeout(() => {
				console.log("order", order);
				self.order = order;
			}, 200);
		},

		closeNewOrder() {
			this.confirmMode = false;
			this.newOrderModalOpened = false;
			this.eater_name = "";
			this.eater_phone = "";
			this.eater_phone_valid = false;
			this.eater_nif = "";
			this.newOrder = {};
			this.searchAddressEnabled = true;
			this.searchText = "";
			this.sugestionsList = [];
			this.quoteId = "";
			this.quoteDistance = 0;
			this.quotePrice = 0;
			this.preparation_time = 20;
			this.address_valid = false;
			this.address_number = "";
			this.appartment_number = "";
			this.confirmObs = "";
			this.address_lat = 0;
			this.address_lng = 0;
		},

		updateDate(date) {
			this.order = {};
			this.currentDate = moment(date).format("YYYY-MM-DD");
			this.loadData();
		},

		onAddressInput(search) {
			const self = this;
			if (search.length <= 5) return;
			this.newOrderLoading = true;
			this.historyMode = false;
			this.confirmMode = false;
			this.confirmObs = this.confirmObsDefault;
			this.address_number = "";
			this.appartment_number = "";
			this.address_number_error = "";

			axios
				.post("/addressSearch", { search: search })
				.then((response) => {
					this.sugestionsList = response.data.predictions ?? [];
				})
				.catch((error) => {
					this.sugestionsList = [];
					console.log(error);
				})
				.finally(() => {
					self.newOrderLoading = false;
				});
		},

		onAddressSelected(suggestion) {
			const self = this;
			console.log("onAddressSelected", suggestion);
			this.confirmMode = true;

			this.confirmPlace = suggestion.terms[0]["value"];
			this.address_number = this.checkIsValidNumber(
				suggestion.terms[1]["value"]
			);
			this.confirmPlace2 = suggestion.structured_formatting.secondary_text;
			this.confirmPlaceId = suggestion.place_id;
			this.newOrderLoading = true;

			axios
				.post("/" + this.user_type + "/check_price", {
					store_id: this.store.id,
					placeId: this.confirmPlaceId,
					address: this.confirmPlace,
					address_number: this.address_number,
					appartment_number: this.appartment_number,
					obs: this.confirmObs,
					delay: this.preparation_time,
				})
				.then((response) => {
					console.log(response);

					if (response.data.quote.error) {
						switch (response.data.quote.error) {
							case "INVALID_DELIVERY_ADDRESS_OUTSIDE_WORKING_AREAS":
							case "LOGISTICS_UNAVAILABLE":
								alert("Morada fora da área de entrega");
								break;
							case "PATH_LARGER_THAN_RADIUS":
								alert("Morada muito longe de entrega");
								break;
							default:
								alert(response.data.quote.error);
								break;
						}

						this.searchAddressEnabled = true;
						this.searchText = "";
						this.sugestionsList = [];
						this.confirmMode = false;
						this.address_valid = false;
						return;
					}

					self.quoteId = response.data.quote.id;
					self.quotePrice = parseFloat(response.data.quote.price, 2).toFixed(2);
					self.quoteDistance = parseFloat(response.data.quote.distance).toFixed(
						1
					);
					self.quoteExpiration = response.data.quote.expiresAt;

					self.address = response.data.address.address ?? "";
					self.address_number = response.data.address.address_number ?? "";
					self.address_zipcode = response.data.address.zipcode ?? "";
					self.address_lat = response.data.address.lat ?? null;
					self.address_lng = response.data.address.lng ?? null;
					self.address_area_level_1 =
						response.data.address.area_level_1 ?? null;
					self.address_area_level_2 =
						response.data.address.area_level_2 ?? null;
					self.address_area_level_3 =
						response.data.address.area_level_3 ?? null;
					self.address_country = response.data.address.country ?? null;
					self.address_valid = true;

					self.searchAddressEnabled = false;
				})
				.catch((error) => {
					self.error =
						error.response?.data?.error ??
						"Ocorreu um erro, tente novamente por favor!";
					console.log("errorrrrr", error.response);
					alert(self.error);
				})
				.finally(() => {
					self.newOrderLoading = false;
					self.loading = false;
				});
		},

		updateInternalValue(event) {
			this.touched = true;
			this.updateValue(event.target.value);
		},
		updateValue: _debounce(function (value) {
			this.touched = false;
			this.onAddressInput(value);
		}, 600),

		callToNumber(phonenumber) {
			if (phonenumber.toUpperCase().includes("CODE")) {
				const phoneSplited = phonenumber
					.toUpperCase()
					.replace(" ", "")
					.split("CODE");
				try {
					window.location =
						"tel:" + phoneSplited[0] + ",," + phoneSplited[1] + "#";
				} catch (e) {
					alert("Problemas ao ligar");
				}
			} else {
				window.location = "tel:" + phonenumber;
			}
			return false;
		},

		createNewOrderConfirmed() {
			const self = this;
			if (
				confirm(
					"Tem a certeza de que deseja criar o pedido?\nSerá possível cancelar posteriormente, mas poderão ser aplicados custos."
				)
			) {
				self.loading = true;
				this.address_number_error = "";

				if (this.address_number == "") {
					this.address_number_error = "Digite o número da morada";
					return;
				}

				this.confirmMode = false;

				axios
					.post("/" + this.user_type + "/create_order", {
						quoteId: this.quoteId,
						quotePrice: this.quotePrice,
						quoteDistance: this.quoteDistance,
						quoteExpiration: this.quoteExpiration,
						store_id: this.store.id,
						placeId: this.confirmPlaceId,
						address: this.confirmPlace,
						address_number: this.address_number,
						appartment_number: this.appartment_number,
						obs: this.confirmObs,
						delay: this.preparation_time,

						eater_name: this.eater_name,
						eater_phone: this.eater_phone,
						eater_nif: this.eater_nif,

						zipcode: this.address_zipcode,
						lat: this.address_lat,
						lng: this.address_lng,
						area_level_1: this.address_area_level_1,
						area_level_2: this.address_area_level_2,
						area_level_3: this.address_area_level_3,
						country: this.address_country,
					})
					.then((response) => {
						console.log(response);
						self.loadData();
					})
					.catch((error) => {
						self.error =
							error.response?.data?.error ??
							"Ocorreu um erro, tente novamente por favor!";
						alert(error);
					})
					.finally(() => {
						self.closeNewOrder();
					});
			}
		},

		resetCurrentAddress() {
			this.address = "";
			this.address_number = "";
			this.confirmMode = false;
			this.searchText = "";
			this.searchAddressEnabled = true;
			this.address_valid = false;
			this.address_lat = 0;
			this.address_lng = 0;
		},

		async copyShare(order) {
			var Url = `${order.store.url}/tracking/${order.id}`;
			try {
				await navigator.clipboard.writeText(Url);
				alert("Link de acompanhamento copiado para a área de transferência");
			} catch ($e) {
				alert("Cannot copy");
			}
		},

		orderStatus(value) {
			switch (value) {
				case "NEW":
					return "Novo";
				case "ACCEPTED":
					return "Aceito";
				case "READY":
					return "Pronto";
				case "ON_DELIVERY":
					return "Em Entrega";
				case "FINISHED":
				case "DELIVERED":
					return "Finalizado";
				case "CANCELED":
					return "Cancelado";
				case "REJECTED":
					return "Rejeitado";
				case "BACK_TO_NEW":
					return "Devolvido";
				case "BACK_TO_ACCEPT":
					return "Devolvido";
				default:
					return value;
			}
		},

		toCurrency(value, currency = "EUR") {
			value = parseFloat(value, 2);
			if (typeof value !== "number") {
				return value;
			}

			var formatter;

			if (currency == "") {
				formatter = new Intl.NumberFormat("pt-PT", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			} else {
				formatter = new Intl.NumberFormat("pt-PT", {
					style: "currency",
					currency: currency,
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			}

			return formatter.format(value);
		},
	},
};
</script>
